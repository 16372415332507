/*
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2025-01-10 13:05:00
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-12 10:35:39
 * @FilePath: \exam-fe\src\utils\AuthTools.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 登出函数
import { message } from "ant-design-vue";
import { get } from "./request";

export const logout = function () {
    localStorage.removeItem("satoken");
    window.location.replace("/login");
};

export const authCheck = async function (router) {
    try {
        const response = await get("/users/me");
        return {
            username: response.data.username,
            role: response.data.role,
        };
    } catch (error) {
        message.error(error.message, 2, () => router.push("/login"));
    }
};

export const checkAdmin = async function (router) {
    const userInfo = await authCheck(router);
    if (userInfo.role == undefined || userInfo.role != "admin")
        message.error("非法进入", 1, () => router.push("/login"));
};