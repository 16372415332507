<!-- 
管理考试界面 
-->
<template>
    <!-- 删除确认框 -->
    <a-modal
        title="删除确认"
        v-model:open="modalData.open"
        :confirm-loading="modalData.confirmLoading"
        cancelText="取消"
        okText="确认"
        @ok="handleModalOk"
    >
        <p>{{ modalData.text }}</p>
    </a-modal>
    <div id="examManagerMain">
        <a-spin :spinning="loading" size="large" />
        <div id="examManagerActionBar">
            <a-button class="btn_action" type="primary" @click="createAExam">
                创建考试
            </a-button>
            <a-button
                class="btn_action"
                type="primary"
                :disabled="!hasSelected"
                @click="deleteExamList"
            >
                删除选中考试
            </a-button>
            <span>
                <template v-if="hasSelected">
                    {{ `选中了 ${state.selectedRowKeys.length} 项` }}
                </template>
            </span>
            <SearchBar  SearchWidth="300px" @onSearch="getKeyword"/>
        </div>

        <a-table
            v-show="!loading"
            :data-source="examList.exams"
            :columns="columns"
            :rowKey="(record) => record.id"
            :pagination="{
                position: ['bottomCenter'],
                pageSize: examList.size,
                total: examList.total,
                page: examList.page,
                showSizeChanger: true, // 显示每页大小的选择器
                pageSizeOptions: ['10', '15', '20'], // 每页大小的选项
            }"
            :row-selection="{
                selectedKeys: state.selectedRowKeys,
                onChange: onSelectChange,
            }"
            @change="handlePageChange"
        >
            <template
                #customFilterDropdown="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                }"
            >
                <div style="padding: 8px">
                    <a-input
                        ref="searchInput"
                        :placeholder="`Search ${column.dataIndex}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="
                            (e) =>
                                setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                )
                        "
                        @pressEnter="
                            handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                            )
                        "
                    />
                    <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="
                            handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                            )
                        "
                    >
                        <template #icon><SearchOutlined /></template>
                        搜索
                    </a-button>
                    <a-button
                        size="small"
                        style="width: 90px"
                        @click="handleReset(clearFilters)"
                    >
                        重置
                    </a-button>
                </div>
            </template>
            <template #customFilterIcon="{ filtered }">
                <search-outlined
                    :style="{ color: filtered ? '#108ee9' : undefined }"
                />
            </template>
            <template #bodyCell="{ text, column, record }">
                <span
                    v-if="
                        state.searchText &&
                        state.searchedColumn === column.dataIndex
                    "
                >
                    <template
                        v-for="(fragment, i) in text
                            .toString()
                            .split(
                                new RegExp(
                                    `(?<=${state.searchText})|(?=${state.searchText})`,
                                    'i'
                                )
                            )"
                    >
                        <mark
                            v-if="
                                fragment.toLowerCase() ===
                                state.searchText.toLowerCase()
                            "
                            :key="i"
                            class="highlight"
                        >
                            {{ fragment }}
                        </mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </span>
                <span
                    v-else-if="
                        column.dataIndex == 'startTime' ||
                        column.dataIndex == 'createdAt' ||
                        column.dataIndex == 'updatedAt' ||
                        column.dataIndex == 'endTime'
                    "
                >
                    {{ formatDate(text) }}
                </span>
                <span v-else-if="column.key == 'action'">
                    <a-button
                        class="btn_action"
                        @click="toDetailExam(record.id)"
                        >查看</a-button
                    >
                    <a-button
                        class="btn_action"
                        @click="deleteExam(record.id, record.title)"
                        >删除</a-button
                    >
                </span>
            </template>
        </a-table>
    </div>
</template>
<script setup>
import { fetchExams } from "@/store/ExamListHook";
import { checkAdmin } from "@/utils/AuthTools";
import { formatDate } from "@/utils/TimeTools";
import { SearchOutlined } from "@ant-design/icons-vue";
import { onMounted, reactive, ref, computed, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { deleteExams } from "@/store/ExamListHook";
import SearchBar from "../SearchBar.vue";

// =======================================数据=========================================================================
// ======================工具======================
const router = useRouter();

// ======================普通数据======================
let loading = ref(false);
let examList = reactive({
    total: 0,
    page: 1,
    size: 10,
    exams: [],
});
const searchInput = ref();
const state = reactive({
    searchText: "",
    searchedColumn: "",
    selectedRowKeys: [],
});
// ======================计算数据======================
// eslint-disable-next-line
const hasSelected = computed(() => state.selectedRowKeys.length > 0);
// {"id":7,"title":"测试","createdBy":1,"startTime":"2025-01-05T09:00:00","endTime":"2025-01-08T15:50:00","createdAt":"2025-01-08T07:47:43","updatedAt":"2025-01-08T07:47:43","description":"自动提交测试"}
const columns = [
    {
        title: "考试标题",
        dataIndex: "title",
        key: "id",
        customFilterDropdown: true,
        onFilter: (value, record) =>
            record.title.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        },
    },
    {
        title: "描述",
        dataIndex: "description",
        key: "id",
        ellipsis: true,
    },
    {
        title: "考试开始时间",
        // key: "id",

        dataIndex: "startTime",
        sorter: {
            compare: (a, b) => new Date(a.startTime) - new Date(b.startTime),
            multiple: 4, // 越大优先级越高
        },
    },
    {
        title: "考试结束时间",
        // key: "id",

        dataIndex: "endTime",
        sorter: {
            compare: (a, b) => new Date(a.endTime) - new Date(b.endTime),
            multiple: 3,
        },
    },
    {
        title: "创建时间",
        // key: "id",
        dataIndex: "createdAt",
        sorter: {
            compare: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            multiple: 2,
        },
    },
    {
        title: "更新时间",
        // key: "id",
        dataIndex: "updatedAt",
        sorter: {
            compare: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
            multiple: 1,
        },
    },
    {
        title: "操作",
        key: "action",
        width: "200px",
        align: "center",
    },
];

// 模态框
let modalData = reactive({
    open: false, //是否显示
    text: null, //提示文字
    deleteId: [],
    confirmLoading: false,
});
// ======================监视数据======================

// =======================================方法=========================================================================

// ======================自定义事件======================
const getKeyword = (searchValue) =>{
    fetchExams(examList, router, loading, searchValue);
}
// ======================普通函数======================

// 获取分页符的页码变更
const handlePageChange = function (pagination) {
    if (
        pagination.current != examList.page ||
        pagination.pageSize != examList.size
    ) {
        examList.page = pagination.current;
        examList.size = pagination.pageSize;
        fetchExams(examList, router, loading);
    }
};

// 点击删除按钮后
const deleteExam = (examId, examTitle) => {
    modalData.text = `你将要删除${examTitle}，是否确认删除？`;
    modalData.open = true;
    modalData.deleteId.push(examId);
};
// 点击批量删除按钮后
const deleteExamList = () => {
    modalData.text = `你将要删除${state.selectedRowKeys.length}项考试，是否确认删除？`;
    modalData.open = true;
    state.selectedRowKeys.forEach((item) => {
        modalData.deleteId.push(item.id);
    });
};

// 模态框点击确认后
const handleModalOk = async function () {
    await deleteExams(modalData);
    modalData.open = false;
};


// 点击创建考试
const createAExam = () => router.push('/backend/examManagementCreate');
// 点击查看按钮进入详情页面（编辑）
const toDetailExam = (examId) => {
    router.push({ name: "examManagementDetail", query: { examId: examId } })
};

const onSelectChange = function (selectedRowKeys, selectedRow) {
    state.selectedRowKeys = selectedRow;
};

// 搜索
const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    state.searchText = selectedKeys[0];
    state.searchedColumn = dataIndex;
};
// 重置
const handleReset = (clearFilters) => {
    clearFilters({
        confirm: true,
    });
    state.searchText = "";
};
// ======================生命周期======================
onMounted(() => {
    fetchExams(examList, router, loading);
});
onBeforeMount(() => {
    checkAdmin(router);
});
</script>
<style scoped>
#examManagerMain {
    width: 100%;
    background-color: #fff;
    padding: 24px;
    min-height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
}
#examManagerActionBar {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}
#examManagerActionBar span {
    font-size: 14px;
    margin-left: 10px;
}
.btn_action {
    margin: 0px 5px;
}
.highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
}

</style>
