<!--
 * @Author: 'ShenfanYin' '874321414@qq.com'
 * @Date: 2024-12-27 13:30:30
 * @LastEditors: 'ShenfanYin' 
 * @LastEditTime: 2024-12-27 15:22:40
 * @FilePath: \exam-fe\src\components\PromotionSystem\PromotionSystemMarketingTableComponent/PromotionSystemPageTable.vue
 * @Description: 该组件是一个营销推广组件的我的推广计划表格
-->

<template>
  <div class="MarketingPromotion_box_container_body">
    <p class="MarketingPromotion_box_container_body_title">我的推广计划</p>
    <div class="MarketingPromotion_box_container_body_button">
      <button class="MarketingPromotion_box_container_body_button" @click="startPromotion">启动推广计划</button>
      <button class="MarketingPromotion_box_container_body_button" @click="stopPromotion">停用推广计划</button>
      <button class="MarketingPromotion_box_container_body_button" @click="addPromotionPlan">添加推广计划</button>
      <button class="MarketingPromotion_box_container_body_button">修改推广计划</button>
      <button class="MarketingPromotion_box_container_body_button" @click="deletePromotionPlan">删除推广计划</button>
    </div>
    <div class="MarketingPromotion_box">
      <div class="MarketingPromotion_box_container_body_table">
        <table class="promotion-table">
          <thead>
            <tr>
              <th>序号</th>
              <th>计划名称</th>
              <th>日预算</th>
              <th>推广区域</th>
              <th>推广类型</th>
              <th>状态</th>
              <th>选择</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(plan, index) in plans" :key="index" :class="{'selected': selectedRow === index}" @click="selectRow(index)">
              <td>{{ index + 1 }}</td>
              <td>{{ plan.name }}</td>
              <td>{{ plan.budget }}</td>
              <td>{{ plan.region }}</td>
              <td>{{ plan.type }}</td>
              <td>{{ plan.status }}</td>
              <td>
                <input type="checkbox" v-model="plan.selected" @change="toggleSelectRow(index)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="isModalVisible" class="modal">
      <div class="modal-content">
        <p>{{ message }}</p>
        <button @click="closeModal">关闭</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'PromotionSystemPageTable',
  setup() {
    const plans = ref([]);
    const selectedRow = ref(null);
    const isModalVisible = ref(false);
    const message = ref('');

    const selectRow = (index) => {
      selectedRow.value = selectedRow.value === index ? null : index;
    };

    const toggleSelectRow = (index) => {
      if (!plans.value[index].selected) {
        selectedRow.value = null;
      } else {
        selectedRow.value = index;
      }
    };

    const startPromotion = () => {
      if (selectedRow.value !== null) {
        plans.value[selectedRow.value].status = '启用';
        showModal('推广计划启动成功');
      } else {
        showModal('请先选择一个推广计划');
      }
    };

    const stopPromotion = () => {
      if (selectedRow.value !== null) {
        plans.value[selectedRow.value].status = '停用';
        showModal('推广计划已停用');
      } else {
        showModal('请先选择一个推广计划');
      }
    };

    const showModal = (msg) => {
      message.value = msg;
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const addPromotionPlan = () => {
      const newPlan = {
        name: `推广计划${plans.value.length + 1}`,
        budget: `${(Math.random() * 5000).toFixed(2)}`,
        region: '全国',
        type: '品牌推广',
        status: '停用',
        selected: false
      };
      plans.value.push(newPlan);
    };

    const deletePromotionPlan = () => {
      const selectedPlans = plans.value.filter(plan => plan.selected);
      if (selectedPlans.length > 0) {
        plans.value = plans.value.filter(plan => !plan.selected);
        showModal('选中的推广计划已删除');
      } else {
        showModal('请先选择一个推广计划');
      }
    };

    return {
      plans,
      selectedRow,
      isModalVisible,
      message,
      selectRow,
      toggleSelectRow,
      startPromotion,
      stopPromotion,
      addPromotionPlan,
      deletePromotionPlan,
      closeModal
    };
  }
};
</script>


<style scoped>
.MarketingPromotion_box {
  height: 100%; /* 使用100%确保父容器填充满 */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 对齐到顶部，确保没有垂直间距 */
}

.MarketingPromotion_box_container {
  height: 100vh; /* 使用视口高度，确保内容填满 */
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0; /* 移除任何外边距 */
  padding: 0; /* 移除内边距 */
  box-sizing: border-box; /* 确保包括边框和内边距 */
}

.MarketingPromotion_box_container_body {
  flex: 1;
  background-color: blanchedalmond;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.MarketingPromotion_box_container_body_title {
  margin: 0;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.MarketingPromotion_box_container_body_button {
  
  margin: 0;
  padding: 1rem;
  display: flex;
  justify-content: flex-start; /* 左对齐 */
  gap: 10px; /* 让按钮之间有间隔 */
  padding-left: 1rem; /* 添加左侧间隙 */
}


.promotion-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0; /* 移除外边距 */
  padding: 0; /* 移除内边距 */
}

.promotion-table th,
.promotion-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.promotion-table th {
  background-color: #f4f4f4;
}

.promotion-table tr.selected {
  background-color: #007bff;
  color: white;
}

.promotion-table tr.selected td {
  color: white;
}

.promotion-table input[type="checkbox"]:checked {
  background-color: #007bff;
}

/* 为表格容器添加滚动条 */
.MarketingPromotion_box_container_body_table {
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
}

/* 弹窗样式 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


</style>