<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-23 21:07:30
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-08 10:46:18
 * @FilePath: \exam-fe\src\components\Question\QuestionChoiceBox.vue
 * @Description: 该组件是一个下拉菜单，用于展示题号
-->

<template>
    <a-collapse v-model:activeKey="activeKey">
        <a-collapse-panel key="1" :header="titleMap[title]">
            <div id="main">
                <div
                    class="qbox"
                    :class="{
                        selected: currentQuestion.question.id == item.id,
                        marked: item.isMarked,
                        answered:
                            item.userAnswer != null &&
                            item.userAnswer.length != 0,
                    }"
                    @click="sendCurrentQuestion(item)"
                    v-for="(item, index) in questions"
                    :key="item.id"
                >
                    <div>{{ index + 1 }}</div>
                </div>
            </div>
        </a-collapse-panel>
    </a-collapse>
</template>
<script setup>
import { ref, defineProps, inject, defineEmits } from "vue";
import { titleMap } from "@/utils/titleTools";
// 内置工具
const emit = defineEmits(["clickbox"]);

// 接入数据
// const props = defineProps({
defineProps({
    title: {
        type: String,
        required: true,
    },
    questions: {
        type: Array,
        required: true,
    },
});

// 数据
const activeKey = ref(["1"]);
let currentQuestion = inject("currentQuestion");

// 触发自定义事件向父元素发数据
const sendCurrentQuestion = function (qusetionItem) {
    emit("clickbox", qusetionItem);
};
</script>

<style scoped>
.qbox {
    height: 35px;
    width: 35px;
    padding: 0;
    margin: 5px;
    border: 1px solid rgb(195, 194, 194);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
.qbox:hover {
    border: 1px solid #40a9ff;
    cursor: pointer;
    color: #40a9ff;
}
.qbox:active {
    box-shadow: 0px 0px 5px 1px #40a9ff92;
}
/* 注意顺序，标记样式可以覆盖回答样式，因此标记样式的代码写在下面一点 */
/* 已回答 */
.answered {
    background-color: #efffde;
    border: 1px solid #b8f27a;
    color: black;
}
/* 被标记 */
.marked {
    background-color: #fbf7dc;
    border: 1px solid #f8e77d;
    color: black;
}
/* 被选中 */
.selected {
    background-color: #40a9ff !important;
    border: 1px solid #40a9ff !important;
    color: white !important;
}

.selected:hover {
    color: white !important;
}
#main {
    display: grid;
    width: 100%;
    gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));
}
</style>
