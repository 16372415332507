<!--
 * @Author: 'ShenfanYin' '874321414@qq.com'
 * @Date: 2024-12-26 16:04:30
 * @LastEditors: 'ShenfanYin' 
 * @LastEditTime: 2024-12-25 23:49:40
 * @FilePath: \exam-fe\src\components\PromotionSystem\PromotionSystemMarketingTableComponent\PromotionSystemMarketingPromotion.vue
 * @Description: 该组件是一个营销推广组件的导航栏
-->

<template>
  <div class="MarketingPromotion_box">
    <div class="MarketingPromotion_box_container">
      <div class="MarketingPromotion_box_container_title">
        <p class="MarketingPromotion_box_container_title_navigation">直播间推广</p>
        <p class="MarketingPromotion_box_container_title_navigation">产品推广</p>
        <p class="MarketingPromotion_box_container_title_navigation">搜索推广</p>
        <p class="MarketingPromotion_box_container_title_navigation">推广计划</p>
        <p class="MarketingPromotion_box_container_title_navigation">推广素材</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromotionSystemMarketingPromotion',
  // 子组件可以根据需要传递 props 或监听事件
};
</script>

<style scoped>
.MarketingPromotion_box {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
}
.MarketingPromotion_box_container {
  /* height: 70vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.MarketingPromotion_box_container_title_navigation {
  margin: 2rem;
  cursor: pointer;
}
.MarketingPromotion_box_container_title {
  
  background-color: aqua;
  display: flex;
}
</style>


