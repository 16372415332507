<template>
  <div class="PromotionSystemMyTable">
    <PromotionSystemMarketingPromotion />
    <PromotionSystemPageTable />
  </div>
</template>

<script>
// 导入子组件
import PromotionSystemMarketingPromotion from './PromotionSystemMarketingTableComponent/PromotionSystemPagePromotion.vue';
import PromotionSystemPageTable from './PromotionSystemMarketingTableComponent/PromotionSystemPageTable.vue';

export default {
  name: 'PromotionSystemMyTable',
  components: {
    // 注册子组件
    PromotionSystemMarketingPromotion,
    PromotionSystemPageTable,
  }
};
</script>

<style scoped>
.PromotionSystemMyTable {
  /* 添加你父组件的样式 */
}
</style>
