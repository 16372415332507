/*
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2025-01-09 20:52:22
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-12 16:11:03
 * @FilePath: \exam-fe\src\store\ExamListHook.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { get, del, post } from "@/utils/request";
import { message } from "ant-design-vue";
import dayjs from "dayjs";
export const fetchExams = async (examList, router, loading, keyword=null) => {
    loading.value = true;
    let data = {
        page: examList.page,
        size: examList.size,
    }
    if(keyword != null)
        data.keyword = keyword
    try {
        const response = await get("/exams", data);
        examList.exams = response.data.exams || [];
        examList.total = response.data.total;
        // 测试的时候用
        // for (let i=0; i< 10; i++){
        //     examList.exams.push({"id":7,"title":"测试","createdBy":1,"startTime":"2025-01-05T09:00:00","endTime":"2025-01-08T15:50:00","createdAt":"2025-01-08T07:47:43","updatedAt":"2025-01-08T07:47:43","description":"自动提交测试"})
        // }
        // examList.total += 10
    } catch (error) {
        if (error.code == "401") {
            message.error(error.message, 2, () => router.push("/login"));
        } else {
            message.error(error.message);
        }
    } finally {
        loading.value = false;
    }
};
export const fetchExam = async (examId, loading, examItem) => {
    // title: "",
    // startTime: undefined,
    // endTime: undefined,
    // description: "",
    let oldItem = {
        title: "",
        startTime: undefined,
        endTime: undefined,
        description: "",
    };
    loading.value = true;
    try {
        const response = await get(`/exams/${examId}`);
        examItem.title = response.data.title;
        const startTime = dayjs(response.data.startTime);
        const endTime = dayjs(response.data.endTime);
        examItem.endTime = endTime;
        examItem.startTime = startTime;
        examItem.description = response.data.description;
        Object.assign(oldItem, examItem);
    } catch (error) {
        message.error(error.message);
    } finally {
        loading.value = false;
    }
    return oldItem;
};

export const deleteExams = async(modalData) =>{
    modalData.confirmLoading = true;
    try {
        const response = await del("/exams", modalData.deleteId);
        if (response.code == 200){
            message.success("删除成功")
            window.location.reload();
        }
        else
            message.error(response.message)
    } catch (error) {

        message.error(error.message);
    } finally {
        modalData.confirmLoading = false;
    }
}

// 发送请求创建考试
export const postCreateExam = async(createExamItem, router) => {
    // /api/exams
    try{
        const response = await post('/exams', createExamItem)
        if (response.code == 200){
            message.success("创建成功")
            router.push('/backend/examManagement')
        }
    }catch(error){
        message.error(error);
    }
};