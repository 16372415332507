import { letterToIndex, transLettersToNumbers } from "@/utils/usefulTools";

function TransUserAnswerFactory(questionItem) {
    function handleSingleChoice() {
        return questionItem.userAnswer === "" ||
            questionItem.userAnswer === null
            ? null
            : letterToIndex(questionItem.userAnswer);
    }
    function handleTrueFalse() {
        if (questionItem.userAnswer === "" || questionItem.userAnswer === null)
            return null;
        return questionItem.userAnswer == "true" ? 0 : 1;
    }
    function handleMultipleChoice() {
        return questionItem.userAnswer === "" ||
            questionItem.userAnswer === null
            ? []
            : transLettersToNumbers(questionItem.userAnswer);
    }
    function handleFileUpload() {
        return questionItem.userAnswer;
    }
    return function transUserAnswer() {
        let result;
        switch (questionItem.type) {
            case "SINGLE_CHOICE":
                result = handleSingleChoice();
                break;
            case "TRUE_FALSE":
                result = handleTrueFalse();
                break;
            case "MULTIPLE_CHOICE":
                result = handleMultipleChoice();
                break;
            case "PHOTOSHOP":
                result = handleFileUpload();
                break;
            case "PREMIERE":
                result = handleFileUpload();
                break;
            default:
                console.warn("Unsupported question type:", questionItem.type);
                break;
        }
        return result;
    };
}

export default TransUserAnswerFactory;
