<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-28 22:34:21
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-08 15:39:22
 * @FilePath: \exam-fe\src\components\Question\QuestionDisplayChoices.vue
 * @Description: 选项栏
-->
<template>
    <!-- radio单选、判断 -->
    <div class="choicesContainer" v-if="choiceType == 0">
        <div
            class="choiceItem"
            v-for="(item, index) in currentQuestion.question.options"
            :key="index"
        >
            <span>
                <input
                    type="radio"
                    name="qchoice"
                    :id="index"
                    v-model="currentQuestion.question.userAnswer"
                    :value="index"
                    @change="handleChoiceChange"
                />
                <label :for="index">{{ item }}</label>
            </span>
        </div>
    </div>
    <!-- choice 多选 -->
    <div class="choicesContainer" v-else-if="choiceType == 1">
        <div
            class="choiceItem"
            v-for="(item, index) in currentQuestion.question.options"
            :key="index"
        >
            <span>
                <input
                    type="checkbox"
                    name="qchoice"
                    :id="index"
                    v-model="currentQuestion.question.userAnswer"
                    :value="index"
                    @change="handleChoiceChange"
                />
                <label :for="index">{{ item }}</label>
            </span>
        </div>
    </div>

    <!-- 上传文件 -->
    <div
        class="choicesContainer"
        v-else-if="choiceType == 2"
    >
        <a-upload
            v-model:file-list="fileUploadInfo.fileList"
            :multiple="false"
            :before-upload="beforeUpload"
            @change="handleUploadChange"
        >
            <a-button>
                <upload-outlined></upload-outlined>
                上传文件
            </a-button>
        </a-upload>
        <a-button
            type="primary"
            :disabled="fileUploadInfo.fileList.length === 0 || fileUploadInfo.uploadingSuccess == 1"
            :loading="fileUploadInfo.uploading"
            style="margin-top: 16px"
            @click="handleUpload"
        >
            {{ btnStr }}
        </a-button>
    </div>
</template>

<script setup>
import { UploadOutlined } from "@ant-design/icons-vue";
import {
    inject,
    computed,
    onBeforeUnmount,
    onMounted,
    watch,
    defineProps,
    reactive,
} from "vue";
import HandleChoiceChangeFactory from "@/store/HandleChoiceChangeFactory";
import { debounce } from "@/utils/usefulTools";
import { message } from "ant-design-vue";
import { post } from "@/utils/request";
import { useRoute } from "vue-router";
import { choiceTypeMap, fileTypeMap } from "@/utils/titleTools";
// =======================================数据=========================================================================

// ======================工具======================
const route = useRoute();
// ======================导入数据======================
const currentQuestion = inject("currentQuestion");
const props = defineProps({
    submitFlag: {
        type: Boolean,
        require: true,
    },
});
// ======================普通数据======================
// 控制盒子类型，0：radio， 1：choice
// 用于盒子的展示选择
const choiceType = computed(() => {
    try {
        return choiceTypeMap[currentQuestion.question.type];
    } catch (error) {
        return -1;
    }
});
let examId;
let submitAnswer;
let nowID;
let fileUploadInfo = reactive({
    uploading: false,
    uploadingSuccess: currentQuestion.question.userAnswer && choiceType.value == 2 ? 1 : 0,   // 0 未上传 1成功2失败
    fileTypeCheck: false,
    fileList: [],
});
// TODO: 交过的文件未显示

// ======================计算数据======================

const btnStr = computed(() => {
    return fileUploadInfo.uploadingSuccess == 1 ? "已上传" : "点击上传文件";
});

// ======================监视数据======================
watch(
    () => currentQuestion.question?.id,
    (newId, oldId) => {
        if (newId !== oldId && newId !== undefined) {
        // 题型更换后提交一次
            submitForceCall(nowID, submitAnswer);
            fileUploadInfo.fileList = []
            fileUploadInfo.uploadingSuccess = currentQuestion.question.userAnswer && choiceType.value == 2 ? 1 : 0
        }
    }
);
// 如果题目类型换了，要更新格式化answer的工厂
watch(
    () => currentQuestion.question?.type,
    (newType, oldType) => {
        if (newType !== oldType && newType !== undefined) {
            submitChoiceFormat = HandleChoiceChangeFactory(currentQuestion);
        }
    }
);
watch(
    () => props.submitFlag,
    () => {
        submitForceCall(nowID, submitAnswer);
    }
);
// =======================================方法=========================================================================
// 上传文件变化的时候
const handleUploadChange = function (info) {
    if (fileUploadInfo.fileTypeCheck) {
        fileUploadInfo.uploadingSuccess = 0;
        if (info.fileList.length > 1) info.fileList.shift();
    } else {
        message.error(`请上传${fileTypeMap[currentQuestion.question.type]}文件`);
        info.fileList.pop();
    }
};
// 上传之前检查一下文件类型
const beforeUpload = (file) => {
    if (file.name.split(".").at(-1) != fileTypeMap[currentQuestion.question.type]) fileUploadInfo.fileTypeCheck = false;
    else fileUploadInfo.fileTypeCheck = true;
    return false;
};
// 正式上传
const handleUpload = async () => {
    fileUploadInfo.uploading = true;
    fileUploadInfo.uploadingSuccess = 0;

    const formData = new FormData();
    formData.append("file", fileUploadInfo.fileList[0].originFileObj);
    formData.append("fileType", fileTypeMap[currentQuestion.question.type]);
    currentQuestion.question.userAnswer = fileUploadInfo.fileList[0].name
    submitAnswer = submitChoiceFormat(currentQuestion);
    submitForceCall(nowID, submitAnswer);
    try {
        const response = await post(
            `/files/${examId}/answers/${currentQuestion.question.id}/upload`,
            formData,
            "multipart/form-data"
        );
        if (response && response.code === 200) {
            message.success("上传成功");
            fileUploadInfo.uploadingSuccess = 1;
        } else {
            message.error("上传失败");
            fileUploadInfo.uploadingSuccess = 2;
        }
    } catch (error) {
        message.error(error);
        fileUploadInfo.uploadingSuccess = 2;
    } finally {
        fileUploadInfo.uploading = false;
    }
};
// 格式化answer的工厂
let submitChoiceFormat = HandleChoiceChangeFactory(currentQuestion);
// 提交答案防抖
const [submitSelection, submitForceCall] = debounce(
    async (questionId, userAnswer) => {
        if (questionId != undefined && userAnswer != undefined) {
            try {
                await post("/exams/" + examId + "/answers", {
                    questionId: questionId,
                    userAnswer: userAnswer,
                });
            } catch (error) {
                message.error(error);
            }
        }
    },
    3000
);
// 提交答案相应函数
const handleChoiceChange = function () {
    submitAnswer = submitChoiceFormat(currentQuestion);
    nowID = currentQuestion.question.id;
    submitSelection(nowID, submitAnswer);
};
onMounted(() => {
    examId = route.query.examId;
});
onBeforeUnmount(() => {
    // ======================清理防抖======================
    submitAnswer = submitChoiceFormat(currentQuestion);
    submitForceCall(nowID, submitAnswer);
});
</script>

<style scoped>
.choicesContainer {
    padding: 10px;
    overflow: auto;
}
.choiceItem {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 18px;
}
</style>
