<template>
    <a-input-search
        v-model:value="searchValue"
        :placeholder="placeholder"
        :style="{width: SearchWidth}"
        @search="sendKeyword"
    />
</template>

<script setup>
import { defineProps } from "vue";
import { defineEmits } from "vue";

const emit = defineEmits(["onSearch"]);
let searchValue;

defineProps({
    placeholder: {
        type: String,
        default: "请输入搜索关键词",
    },
    SearchWidth: {
        type: String,
        default: "400px",
    },
});
const sendKeyword = () => {
    emit("onSearch", searchValue);
};
</script>

<style scoped></style>
