<template>
    <div id="gradeHeader">
        <a-breadcrumb id="gradeBreadCrumb">
            <a-breadcrumb-item v-for="(item, index) in breadInfo" :key="index">
                <a :href="item.url" v-if="item.url != null">{{ item.title }}</a>
                <span v-else>{{ item.title }}</span>
            </a-breadcrumb-item>
        </a-breadcrumb>
        <div id="searchBox"></div>
        <div id="goBack" @click="goback">
            <span> <LeftOutlined /> 返回</span>
        </div>
    </div>
</template>

<script setup>
import { LeftOutlined } from "@ant-design/icons-vue";
import { List } from "ant-design-vue";
import { defineProps } from "vue";
import { useRouter } from "vue-router";

// =======================================数据=========================================================================

// ======================工具======================
const router = useRouter();
// ======================外部导入数据======================
defineProps({
    breadInfo: {
        type: List,
        required: true,
    },
});
// ======================普通数据======================

// ======================计算属性======================

// ======================监视属性======================

// =======================================方法=========================================================================

// ======================自定义方法======================
const goback = () => router.back();
// ======================ant方法======================

// ======================生命周期======================
</script>

<style scoped>
#gradeHeader {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin: 20px 0;
}
#gradeBreadCrumb {
    font-size: 14px;
}
#searchBox {
    flex: 1;
}
#goBack {
    width: 80px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}
#goBack:hover {
    background-color: #eae8e894;
}
</style>
