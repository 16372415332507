<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-23 11:55:04
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-12 15:45:11
 * @FilePath: \exam-fe\src\views\BackLayout.vue
 * @Description: 此组件用于显示后台界面（管理员的管理界面）。
-->

<template>
    <a-layout>
        <a-layout-sider
            breakpoint="lg"
            collapsed-width="0"
            @collapse="onCollapse"
            @breakpoint="onBreakpoint"
        >
            <a-menu
                theme="dark"
                mode="inline"
                v-model:selectedKeys="selectedKeys"
            >
                <div id="userTitle">
                    <span>互联网营销师</span>
                </div>
                <!-- <a-menu-item key="infoManager">
                    <home-outlined />
                    <span class="nav-text">个人信息管理</span>
                </a-menu-item> -->
                <router-link to="/backend/examManagement">
                    <a-menu-item key="examManagement">
                        <exception-outlined />
                        <span class="nav-text">考试管理</span>
                    </a-menu-item>
                </router-link>
                <router-link to="/backend/gradeManagement">
                    <a-menu-item key="gradeManagement">
                        <LineChartOutlined />
                        <span class="nav-text">成绩查看</span>
                    </a-menu-item>
                </router-link>
                <router-link to="/userManager">
                    <a-menu-item key="userManager">
                        <LineChartOutlined />
                        <span class="nav-text">前台</span>
                    </a-menu-item>
                </router-link>
            </a-menu>
        </a-layout-sider>
        <a-layout id="rightContainer">
            <div id="rightHeader">
                <a-dropdown placement="bottom">
                    <a
                        class="ant-dropdown-link"
                        @click.prevent
                        id="userNameDropdown"
                    >
                        <span>{{ username }}</span>
                    </a>
                    <template #overlay>
                        <a-menu id="logoutMenu">
                            <a-menu-item @click="logout" id="logoutItem">
                                <span>登出</span>
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
            </div>
            <a-layout-content :style="{ margin: '24px 16px' }">
                <div id="routerViewBox">
                    <router-view></router-view>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script setup>
import {
    LineChartOutlined,
    // UserOutlined,
    ExceptionOutlined,
    // HomeOutlined,
    // TeamOutlined,
} from "@ant-design/icons-vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { logout, checkAdmin, authCheck } from "@/utils/AuthTools";

import { getLastSegmentOfCurrentRoute } from "@/utils/RouteTools";
import { useRouter } from "vue-router";

// =======================================数据=========================================================================
// ======================工具======================
const route = useRoute();
const router = useRouter();
// ======================普通数据======================
let username = ref("");

// =======================================方法=========================================================================



const getInfo = async function(){
  const userInfo = await authCheck(router);
  if (userInfo.username == undefined || !userInfo.username) router.push("/");
  username.value = userInfo.username
}


const getSelectedKeys = function (lastPath) {
    if (lastPath.includes("grade")) return "gradeManagement";
    if (lastPath.includes("exam"))  return "examManagement";
    else return lastPath;
};
const selectedKeys = ref([
    getSelectedKeys(getLastSegmentOfCurrentRoute(route.fullPath)),
]);

// ======================生命周期======================

onMounted(() => {
    checkAdmin(router);
    getInfo()
});

// ======================自带，暂时无用的函数======================

const onCollapse = (collapsed, type) => {
    console.log(collapsed, type);
};
const onBreakpoint = (broken) => {
    console.log(broken);
};
</script>
<style scoped>
#rightHeader {
    background-color: #fff;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
#rightContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
#logoutItem span {
    display: flex;
    justify-content: center;
    align-items: center;
}
#logoutMenu {
    z-index: 10;
}
#userNameDropdown{
    margin-right: 50px;
    padding: 10px;
    font-size: 16px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
#routerViewBox {
    background-color: #fff;
    height: 100%;
    width: 100%;
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.5);
    overflow: auto;
}
.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}
#userTitle {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
#userTitle span {
    font-size: 16px;
}
[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}
</style>
