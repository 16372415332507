<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-27 14:37:27
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-09 20:49:25
 * @FilePath: \exam-fe\src\views\LoginLayout.vue
 * @Description: 登录界面
-->
<template>
    <div id="content">
        <!-- 左侧，后期换一个图片 -->
        <div id="bgc"></div>
        <!-- 右侧登录主体 -->
        <div id="mainLogin">
            <!-- 标题 -->
            <div id="title"><span>职业技能等级认定考试</span></div>
            <div id="examTitle">
                <span>互联网营销师</span>
            </div>

            <!-- 用户名密码 -->
            <div id="inputContatiner">
                <div class="inputBox">
                    <label for="userName"><span>用户名</span></label>
                    <input
                        type="text"
                        id="userName"
                        placeholder="请输入用户名"
                        v-model="username"
                    />
                </div>
                <div class="inputBox">
                    <label for="passWord"><span>密码</span></label>
                    <input
                        type="password"
                        id="passWord"
                        placeholder="请输入密码"
                        v-model="password"
                    />
                </div>
                <div id="btnLogin">
                    <button @click="handleLogin">登录</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { post } from "@/utils/request";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { authCheck } from "@/utils/AuthTools";

let username = ref("");
let password = ref("");
const router = useRouter();

async function handleLogin() {
    try {
        // 调用post请求，发送用户名和密码到后端接口
        const response = await post(
            "/users/login",
            {
                username: username.value,
                password: password.value,
            },
            'application/x-www-form-urlencoded'
        );
        // 根据后端返回的响应处理逻辑
        if (response && response.code === 200) {
            // 登录成功，保存token
            localStorage.setItem("satoken", response.data);
            const userInfo = authCheck(router);
            if ((await userInfo).role == "admin")
                message.success("登录成功，3秒后跳转页面", 3, () => router.push("/backend"));
            else
                message.success("登录成功，3秒后跳转页面", 3, () => router.push("/userManager"));
        } else {
            message.info("用户名或密码错误");
        }
    } catch (error) {
        // 错误处理：根据返回的错误信息提示用户
        if (error && error.message) {
            message.error(error.message); // 弹窗提示或直接显示在页面上
        } else {
            message.error("发生了未知错误");
        }
    } finally {
        username.value = "";
        password.value = "";
    }
}

</script>

<style scoped>
.checkbox {
    flex: 1;
}
.inputBox {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: stretch;
}
.inputBox label {
    text-align: left;
    display: flex;
    align-items: center;
    letter-spacing: 3px;
}
.inputBox input {
    margin-top: 10px;
    height: 30px;
}
.inputBox input::placeholder {
    font-size: 14px;
    letter-spacing: 2px;
}
#inputContatiner {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
}
#btnLogin {
    height: 60px;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: stretch;
    padding: 10px 0px;
}
#btnLogin button {
    width: 100%;
    font-size: 20px;
    color: white;
    letter-spacing: 4px;
    font-weight: 500;
    border-radius: 10px;
    border: 0;
    background-color: #4b6ef0;
}
#btnLogin button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 15px rgba(52, 51, 51, 0.418);
}
#examTitle {
    font-size: 25px;
    color: rgb(158, 156, 156);
    height: 80px;
    display: flex;
    letter-spacing: 3px;
    justify-content: center;
    align-items: center;
}
#title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 4px;
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
#content {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
#mainLogin {
    width: 500px;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
    background-color: rgb(255, 255, 255);
    padding: 30px 30px 30px 30px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.226);
    overflow: hidden;
    border-radius: 5px;
}
#bgc {
    width: 500px;
    height: 550px;
    position: relative;
    top: 30px;
    background-color: #c1d9f4;
    overflow: hidden;
}
#bgc::after {
    display: block;
    width: 200vh;
    height: 240vh;
    content: "";
    background-color: #4b6ef0;
    border-radius: 100vh;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% 0;
    animation: spin 15s linear infinite;
}
/* #bgc {
    position: absolute;
    content: "";
    width: 450px;
    height: 800px;
    background-color: rgb(209, 193, 255);
    transform: translate(-50%, -50%) rotate(50deg);
    left: 50%;
    top: 50%;
    z-index: 1;
    border-radius: 30px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.082);
    overflow: visible;
} */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
