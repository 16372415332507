import axios from "axios";
import router from '@/router';
import qs from 'qs'

const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api"
    : "http://localhost:8088/api";

const request = axios.create({
  baseURL,
  timeout: 10000,
});

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // 判断是否是 POST 请求，如果是且需要以 x-www-form-urlencoded 格式发送
    if (config.method === 'post' && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data);  // 转换数据为 URL 编码格式
    }

    return config;
  },
  (error) => {
    console.error("请求错误:", error);
    return Promise.reject(error);
  }
);


request.interceptors.response.use(
  (response) => {

    const { data } = response;
    if (data.code !== 200) {
      console.warn("接口返回异常:", data.message || "未知错误");
      return Promise.reject(data);
    }
    return data;
  },
  (error) => {

    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 401:
          console.error("未授权，请登录");
          localStorage.removeItem('satoken');
          router.push('/login');
          break;
        case 404:
          console.error("请求地址不存在");
          break;
        default:
          console.error(`请求错误: ${status}`);
      }
    } else {
      console.error("网络异常或请求超时");
    }
    return Promise.reject(error);
  }
);

export const get = (url, params = {}) => {
  return request.get(url, { params });
};

// 通用 POST 请求方法，支持两种方式：默认以 JSON 格式发送数据，或者使用 application/x-www-form-urlencoded 格式
export const post = (url, data = {}, contentType = false) => {
  // 'application/x-www-form-urlencoded'
  const headers = contentType
    ? { 'Content-Type': contentType }  // 如果需要 URL 编码格式
    : {};  // 默认发送 JSON 格式

  // 返回请求
  return request.post(url, data, { headers });
};

export const put = (url, data = {}, contentType = false) => {
  const headers = contentType
    ? { 'Content-Type': contentType }
    : {};

  return request.put(url, data, { headers });
};


export const del = (url, data = null, config = {}) => {
  // 如果有数据，添加到配置中
  if (data !== null) {
    config.data = data;
  }

  // 返回请求
  return request.delete(url, config);
};

export default request;
