<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-19 22:44:53
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 10:07:36
 * @FilePath: \exam-fe\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- 设置antdesign全局中文 -->
    <a-config-provider :locale="locale">
        <router-view />
    </a-config-provider>
</template>

<script setup>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
const locale = zhCN;
</script>

<style>
/* 必要全局样式，避免页面出现莫名其妙的滚动条 */
body {
    margin: 0;
    overflow: hidden;
}
</style>
