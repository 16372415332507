<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-28 23:41:15
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-08 14:39:05
 * @FilePath: \exam-fe\src\components\Question\QuestionDisplayBar.vue
 * @Description: 控制栏：上一题下一题标记
-->
<template>
    <div id="barContainer">
        <div id="infoContainer">
            <div class="infoItem">
                <span>题目数量：{{ totalNumber }} </span>
                <span>已答题：{{ AnswerNumber }}</span>
                <span>未完成：{{ totalNumber - AnswerNumber }}</span>
            </div>
            <div class="infoItem">
                <span>已完成</span>
                <div id="colorAnswered" class="colorType"></div>
                <span>标记</span>
                <div id="colorMarked" class="colorType"></div>
                <span>当前题目</span>
                <div id="colorSelected" class="colorType"></div>
            </div>
        </div>
        <LeftSquareTwoTone
            class="barBtn"
            title="上一题"
            @click="sendPredQuestion"
        />
        <StarTwoTone
            class="barBtn"
            title="标记本题"
            :two-tone-color="isTwoToneColor"
            @click="sendIsMarked"
        />
        <RightSquareTwoTone
            class="barBtn"
            title="下一题"
            @click="sendNextQuestion"
        />
    </div>
</template>

<script setup>
import {
    LeftSquareTwoTone,
    RightSquareTwoTone,
    StarTwoTone,
} from "@ant-design/icons-vue";
import { inject, computed, defineProps } from "vue";
import { questionEmitter } from "@/store/bus";

// =======================================数据=========================================================================
// ======================工具======================

// 导入数据
const currentQuestion = inject("currentQuestion");
defineProps({
    totalNumber: {
        type: Number,
        default: 0,
    },
    AnswerNumber: {
        type: Number,
        default: 0,
    },
});
// 计算属性，控制标记本题的样式
const isTwoToneColor = computed(() => {
    return currentQuestion.question.isMarked ? "#f8e77d" : null;
});
// ======================监视数据======================


// 事件总线，传递标记数据
// 传递mark数值，去往：views\FrontLayout.vue
const sendIsMarked = () => {
    questionEmitter.emit("changeMarkedStatus");
};
// 传递下一题，去往：views\FrontLayout.vue
const sendNextQuestion = () => {
    questionEmitter.emit("toNextQuestion");
};
// 传递上一题，去往：views\FrontLayout.vue
const sendPredQuestion = () => {
    questionEmitter.emit("toPredQuestion");
};
</script>

<style>
.barBtn {
    cursor: pointer;
    margin: 10px;
}
.barBtn svg {
    width: 35px !important;
    height: 35px !important;
}
#barContainer {
    width: 100%;
    height: 100%;
    display: flex;
    border-top: 1px solid;
    justify-content: center;
    position: relative;
    align-items: center;
    border-top: 1px solid rgba(176, 176, 176, 0.579);
}
#infoContainer {
    position: absolute;
    height: 100%;
    left: 10px;
    display: flex;
    flex-direction: column;
}
.infoItem {
    flex: 1;
    display: flex;
    align-items: center;
}
.infoItem span {
    font-size: 16px;
    margin-left: 10px;
}
.colorType {
    width: 18px;
    height: 18px;
    margin-left: 5px;
}
#colorAnswered {
    background-color: #b8f27a;
}
#colorMarked {
    background-color: #f8e77d;
}
#colorSelected {
    background-color: #40a9ff;
}
</style>
