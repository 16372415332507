<template>
    <div id="examManagerMain">
        <HeaderBreadBack :breadInfo="breadInfo" />
        <div id="examManagerDisplay">
            <a-form ref="formRef" :model="formState" :rules="rules">
                <a-form-item ref="title" label="考试标题" name="title">
                    <a-input
                        v-model:value="formState.title"
                        placeholder="输入考试标题"
                    />
                </a-form-item>
                <!-- <a-form-item label="Activity zone" name="region">
                    <a-select
                        v-model:value="formState.region"
                        placeholder="please select your zone"
                    >
                        <a-select-option value="shanghai"
                            >Zone one</a-select-option
                        >
                        <a-select-option value="beijing"
                            >Zone two</a-select-option
                        >
                    </a-select>
                </a-form-item> -->
                <a-form-item label="开始时间" required name="startTime">
                    <a-date-picker
                        v-model:value="formState.startTime"
                        show-time
                        type="date"
                        placeholder="请选择考试开始时间"
                        style="width: 100%"
                    />
                </a-form-item>
                <a-form-item label="结束时间" required name="endTime">
                    <a-date-picker
                        v-model:value="formState.endTime"
                        show-time
                        type="date"
                        placeholder="请选择考试结束时间"
                        style="width: 100%"
                    />
                </a-form-item>
                <a-form-item label="考试描述" name="description">
                    <a-textarea v-model:value="formState.description" />
                </a-form-item>
                <div id="examManagerBtnContainer">
                    <a-button type="primary" @click="createExam">创建</a-button>
                    <a-button style="margin-left: 10px" @click="resetForm"
                        >重置</a-button
                    >
                </div>
            </a-form>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, toRaw } from "vue";
import HeaderBreadBack from "./HeaderBreadBack.vue";
import { message } from "ant-design-vue";
import { postCreateExam } from "@/store/ExamListHook";
import { useRouter } from "vue-router";

// =======================================数据=========================================================================

// ======================工具======================
const router = useRouter()
// ======================外部导入数据======================

// ======================普通数据======================
// 面包屑传入数据
const breadInfo = ref([
    {
        title: "考试管理",
        url: "/backend/examManagement",
    },
    {
        title: "创建考试",
        url: null,
    },
]);

const formRef = ref();
const formState = reactive({
    title: "",
    startTime: undefined,
    endTime: undefined,
    description: "",
});
const rules = {
    title: [
        {
            required: true,
            message: "请输入考试的标题",
            trigger: "blur",
        },
        // {
        //     min: 1,
        //     // max: 3,
        //     message: "长度大于1",
        //     trigger: "blur",
        // },
    ],
    startTime: [
        {
            required: true,
            message: "请选择考试开始时间",
            trigger: "change",
            type: "object",
        },
    ],
    endTime: [
        {
            required: true,
            message: "请选择考试结束时间",
            trigger: "change",
            type: "object",
        },
    ],
    description: [
        {
            required: true,
            message: "请输入考试的描述",
            trigger: "blur",
        },
    ],
};

// ======================计算属性======================

// ======================监视属性======================

// =======================================方法=========================================================================

// ======================自定义方法======================

// 转时间格式
const toDateString = (formDate) => {
    // 2025-12-25T11:00:00
    let [year, month, day] = formDate.toLocaleDateString().split("/");
    month = month.length == 1 ? "0" + month : month;
    day = day.length == 1 ? "0" + day : day;
    return `${year}-${month}-${day}T${formDate.toLocaleTimeString()}`;
};

const createExam = () => {
    formRef.value
        .validate()
        .then(() => {
            const rawForm = toRaw(formState);
            const startTime = toDateString(rawForm.startTime.$d);
            const endTime = toDateString(rawForm.endTime.$d);
            if (new Date(startTime) >= new Date(endTime)) {
                throw new Error("结束时间应当晚于开始时间");
            }
            const createExamItem = {
                title: rawForm.title,
                description: rawForm.description,
                startTime: startTime,
                endTime: endTime,
            };
            postCreateExam(createExamItem, router)
        })
        .catch((error) => {
            if (error.errorFields) message.error(error.errorFields[0].errors);
            else message.error(error.message);
        });
};
const resetForm = () => {
    formRef.value.resetFields();
};

// ======================ant方法======================

// ======================生命周期======================
</script>

<style scoped>
#examManagerMain {
    width: 100%;
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}
#examManagerDisplay {
    flex: 1;
    width: 800px;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
}
#examManagerBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
