/*
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-25 22:38:23
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 14:14:09
 * @FilePath: \exam-fe\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import {
    Button,
    Layout,
    Menu,
    Collapse,
    Slider,
    Empty,
    Dropdown,
    Pagination,
    Result,
    Modal,
    Table,
    Input,
    ConfigProvider,
    Spin,
    Breadcrumb,
    Tag,
    Row,
    Col,
    Card,
    Upload,
    Statistic,
    Form,
    FormItem,
    Select,
    DatePicker,
} from "ant-design-vue";
const app = createApp(App);

const plugins = [
    Button,
    Layout,
    Menu,
    Collapse,
    Slider,
    Empty,
    Dropdown,
    Pagination,
    Result,
    Modal,
    Table,
    Input,
    ConfigProvider,
    Spin,
    Breadcrumb,
    Tag,
    Row,
    Col,
    Card,
    Upload,
    Statistic,
    Form,
    FormItem,
    Select,
    DatePicker

];

plugins.forEach((plugin) => app.use(plugin));

app.use(router).mount("#app");
