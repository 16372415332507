<template>
    <div id="gradeManagerMain">
        <a-spin :spinning="loading" size="large" />
        <div id="gradeDisplayMain" v-show="!loading">
            <HeaderBreadBack :breadInfo="breadInfo" />
            <div id="gradeBody">
                <a-table
                    :data-source="gradeList.grades"
                    :columns="columns"
                    :pagination="{
                        position: ['bottomCenter'],
                        pageSize: gradeList.size,
                        total: gradeList.total,
                        page: gradeList.page,
                        showSizeChanger: true, // 显示每页大小的选择器
                        pageSizeOptions: ['10', '15', '20'], // 每页大小的选项
                    }"
                    @change="handlePageChange"
                >
                    <template
                        #customFilterDropdown="{
                            setSelectedKeys,
                            selectedKeys,
                            confirm,
                            clearFilters,
                            column,
                        }"
                    >
                        <div style="padding: 8px">
                            <a-input
                                ref="searchInput"
                                :placeholder="`Search ${column.dataIndex}`"
                                :value="selectedKeys[0]"
                                style="
                                    width: 188px;
                                    margin-bottom: 8px;
                                    display: block;
                                "
                                @change="
                                    (e) =>
                                        setSelectedKeys(
                                            e.target.value
                                                ? [e.target.value]
                                                : []
                                        )
                                "
                                @pressEnter="
                                    handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.dataIndex
                                    )
                                "
                            />
                            <a-button
                                type="primary"
                                size="small"
                                style="width: 90px; margin-right: 8px"
                                @click="
                                    handleSearch(
                                        selectedKeys,
                                        confirm,
                                        column.dataIndex
                                    )
                                "
                            >
                                <template #icon><SearchOutlined /></template>
                                搜索
                            </a-button>
                            <a-button
                                size="small"
                                style="width: 90px"
                                @click="handleReset(clearFilters)"
                            >
                                重置
                            </a-button>
                        </div>
                    </template>
                    <template #customFilterIcon="{ filtered }">
                        <search-outlined
                            :style="{ color: filtered ? '#108ee9' : undefined }"
                        />
                    </template>
                    <template #bodyCell="{ text, column, record }">
                        <span
                            v-if="
                                state.searchText &&
                                state.searchedColumn === column.dataIndex
                            "
                        >
                            <template
                                v-for="(fragment, i) in text
                                    .toString()
                                    .split(
                                        new RegExp(
                                            `(?<=${state.searchText})|(?=${state.searchText})`,
                                            'i'
                                        )
                                    )"
                            >
                                <mark
                                    v-if="
                                        fragment.toLowerCase() ===
                                        state.searchText.toLowerCase()
                                    "
                                    :key="i"
                                    class="highlight"
                                >
                                    {{ fragment }}
                                </mark>
                                <template v-else>{{ fragment }}</template>
                            </template>
                        </span>
                        <span v-else-if="column.dataIndex == 'finishedAt'">
                            {{ formatDate(text) }}
                        </span>
                        <span v-else-if="column.dataIndex == 'status'">
                            <a-tag
                                color="processing"
                                v-if="text == 'in_progress'"
                            >
                                <template #icon>
                                    <sync-outlined :spin="true" />
                                </template>
                                进行中
                            </a-tag>
                            <a-tag
                                color="success"
                                v-else-if="text == 'completed'"
                            >
                                <template #icon>
                                    <check-circle-outlined />
                                </template>
                                success
                            </a-tag>
                        </span>
                        <span v-else-if="column.key == 'action'">
                            <a-button
                                @click="
                                    toGradeDetailLink(
                                        record.userId,
                                        record.username
                                    )
                                "
                                >查看详情</a-button
                            >
                        </span>
                    </template>
                </a-table>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    SearchOutlined,
    SyncOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons-vue";
import { formatDate } from "@/utils/TimeTools";
import HeaderBreadBack from "./HeaderBreadBack.vue";
import { onMounted, reactive, ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { get } from "@/utils/request";
import { checkAdmin } from "@/utils/AuthTools";
// =======================================数据=========================================================================
// ======================工具======================
const route = useRoute();
const router = useRouter();
// ======================普通数据======================
const searchInput = ref();
const state = reactive({
    searchText: "",
    searchedColumn: "",
});
// const statusMap = {
//     in_progress: "进行中",
//     completed: "已完成",
// };
const columns = [
    {
        title: "用户名",
        dataIndex: "username",
        key: "userId",
        customFilterDropdown: true,
        onFilter: (value, record) =>
            record.username
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        },
    },
    {
        title: "考试状态",
        dataIndex: "status",
        key: "id",
        // filters: [
        //     {
        //         text: statusMap["completed"],
        //         value: "completed",
        //     },
        //     {
        //         text: statusMap["in_progress"],
        //         value: "in_progress",
        //     },
        // ],
        // filterMode: "menu",
        // filterSearch: false,
        // onFilter: (value, record) => record.status.startsWith(value),
    },
    {
        title: "分数",
        dataIndex: "score",
        key: "id",
        sorter: {
            compare: (a, b) => a.score - b.score,
            multiple: 2, // 越大优先级越高
        },
    },
    {
        title: "提交时间",
        dataIndex: "finishedAt",
        key: "id",
        sorter: {
            compare: (a, b) => new Date(a.startTime) - new Date(b.startTime),
            multiple: 1, // 越大优先级越高
        },
    },
    {
        title: "操作",
        key: "action",
        width: "100px",
        align: "center",
    },
];
const examId = route.query.examId;
const examTitle = decodeURIComponent(route.query.title);
let loading = ref(false);
const gradeList = reactive({
    total: 0,
    page: 1,
    size: 10,
    grades: [],
});
const breadInfo = ref([
    {
        title: "成绩查看",
        url: "/backend/gradeManagement",
    },
    {
        title: examTitle,
        url: null,
    },
]);
// =======================================方法=========================================================================
const fetchGrade = async function () {
    loading.value = true;
    try {
        const response = await get(`/records/${examId}/scores`, {
            page: gradeList.page,
            size: gradeList.size,
        });
        gradeList.grades = response.data.grades || [];
        gradeList.total = response.data.total;
    } catch (error) {
        if (error.code == "401") {
            message.error(error.message, 2, () => router.push("/login"));
        } else {
            message.error(error.message);
        }
    } finally {
        loading.value = false;
    }
};
const toGradeDetailLink = (userId, username) =>
    router.push(
        `/backend/gradeManagementDetail?examId=${examId}&userId=${userId}&examTitle=${encodeURIComponent(
            examTitle
        )}&username=${username}`
    );

// ======================自定义事件======================
// 获取分页符的页码变更

const handlePageChange = function (pagination) {
    if(pagination.current != gradeList.page || pagination.pageSize != gradeList.size){
        gradeList.page = pagination.current
        gradeList.size = pagination.pageSize
        fetchGrade();
    }
    
};
// 搜索
const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    state.searchText = selectedKeys[0];
    state.searchedColumn = dataIndex;
};
// 重置
const handleReset = (clearFilters) => {
    clearFilters({
        confirm: true,
    });
    state.searchText = "";
};
// ======================生命周期======================
onMounted(() => {
    fetchGrade();
});
onBeforeMount(() => {
    checkAdmin(router);
})
</script>

<style scoped>
#gradeBreadCrumb {
    font-size: 14px;
}
#searchBox {
    flex: 1;
}
#goBack {
    width: 80px;
    border-radius: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}
#gradeManagerMain {
    width: 100%;
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
}
#gradeDisplayMain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
#gradeHeader {
    width: 100%;
    height: 60px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}
#gradeBody {
    flex: 1;
    width: 100%;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: flex-start;
}
</style>
