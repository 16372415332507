<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-24 22:25:17
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2024-12-29 14:29:42
 * @FilePath: \exam-fe\src\components\Question\QuestionDisplayBox.vue
 * @Description: 一个展示框，就是显示题目而已
-->
<template>
    <div class="questionText">
        <p class="questionTitle">
            【 {{ titleMap[currentQuestion.question.type] }} 】&nbsp; 第
            {{ currentQuestion.displayedId }} 题
        </p>
        <pre class="questionContent">{{currentQuestion.question.content}}</pre>
    </div>
</template>
<script setup>
import { inject } from "vue";
import { titleMap } from "@/utils/titleTools";
let currentQuestion = inject("currentQuestion");
</script>

<style scoped>
.questionContent {
    font-size: 18px;
    text-indent: 2em;
    word-break: break-all; /* 强制在任何字符处换行 */
    overflow-wrap: break-word; /* 允许长单词或 URL 断行 */
    white-space: pre-wrap; /* 保留空白符序列，但正常地换行 */
}
.questionText {
    font-size: 18px;
    overflow: auto;
    height: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(176, 176, 176, 0.579);

}
</style>
