<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-23 11:55:04
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 15:44:08
 * @FilePath: \exam-fe\src\views\BackLayout.vue
 * @Description: 此组件用于显示后台界面（管理员的管理界面）。
-->

<template>
    <a-layout>
        <a-layout-sider
            breakpoint="lg"
            collapsed-width="0"
            @collapse="onCollapse"
            @breakpoint="onBreakpoint"
        >
            <a-menu
                theme="dark"
                mode="inline"
                v-model:selectedKeys="selectedKeys"
                id="userMenu"
            >
                <div id="userTitle">
                    <span>互联网营销师</span>
                </div>
                <router-link to="/userManager/userCheckInfo">
                    <a-menu-item key="1">
                        <user-outlined />
                        <span class="nav-text">信息确认</span>
                    </a-menu-item>
                </router-link>

                <router-link to="/userManager/userExamList">
                    <a-menu-item key="2">
                        <exception-outlined />
                        <span class="nav-text">考试列表</span>
                    </a-menu-item>
                </router-link>
            </a-menu>
        </a-layout-sider>
        <a-layout id="rightContainer">
            <div id="rightHeader">
                <a-dropdown placement="bottom">
                    <a class="ant-dropdown-link" @click.prevent  id="userNameDropdown">
                        <span>{{ username }}</span>
                    </a>
                    <template #overlay>
                        <a-menu id="logoutMenu">
                            <a-menu-item  @click="logout" id="logoutItem">
                                <span>登出</span>
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
            </div>
            <a-layout-content :style="{ margin: '24px 16px' }">
                <div id="mainContent">
                    <router-view></router-view>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script setup>
import {
    UserOutlined,
    ExceptionOutlined,
} from "@ant-design/icons-vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { logout, authCheck } from "@/utils/AuthTools";
const router = useRouter();
const onCollapse = () => {};  //collapsed, type
const onBreakpoint = () => {};  //broken
let selectedKeys = ref(["1"]);
let username = ref('')
const getInfo = async function(){
  const userInfo = await authCheck(router);
  if (userInfo.username == undefined || !userInfo.username) router.push("/");
  username.value = userInfo.username
}

onMounted(() => {
    getInfo()
    router.push("/userManager/userCheckInfo");
});
</script>
<style scoped>
#logoutItem span{
    display: flex;
    justify-content: center;
    align-items: center;
}
#logoutMenu{
    z-index: 10;
}
/* #logout{
    width: 100%;
    font-size: 14px;
} */
#userNameDropdown{
    margin-right: 50px;
    padding: 10px;
    font-size: 16px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
#rightHeader {
    background-color: #fff;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
#rightContainer {
    width: 100vw;
    height: 100vh;
}
#mainContent {
    background-color: #fff;
    height: 100%;
    width: 100%;
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.5);
}
#userTitle {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
#userTitle span {
    font-size: 16px;
}
.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}
</style>
