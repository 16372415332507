/*
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-29 17:41:23
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 13:06:41
 * @FilePath: \exam-fe\src\utils\usefulTools.js
 * @Description: 一些工具
 */

// 防抖动函数，第一个参数是要防抖动的函数，第二个参数是等待时间，单位毫秒（1000=1s）
// 应该还需要一个刷新条件函数
export const debounce = function (fn, delay) {
    let deTimeout;
    let lastId = null;

    const debouncedFn = (id, ...args) => {
        if (lastId == null || id != lastId) {
            // 如果是首次调用或者 id 发生变化，则立即执行函数
            fn(id, ...args);
            lastId = id;
        } else {
            // 对于相同的 id，设置延迟执行
            if (deTimeout) clearTimeout(deTimeout);
            deTimeout = setTimeout(() => {
                fn(id, ...args);
            }, delay);
        }
    };

    const forceCall = (id, ...args) => {
        fn(id, ...args); // 强制调用函数
        clearTimeout(deTimeout);
    };

    return [debouncedFn, forceCall];
};

// 转换数字到字母
export const numberToLetters = function (num) {
    if (num < 0 || num > 25) {
        throw new Error("Number must be between 0 and 25");
    }
    // 'A' 的 charCode 是 65，所以通过加上 num 并使用 String.fromCharCode 可以得到对应的字母
    return String.fromCharCode(65 + num);
};
// 将选项字符串变成数字列表
export const transLettersToNumbers = function (answerArr) {
    answerArr = answerArr.split("|||");
    answerArr.forEach(function (value, index, array) {
        array[index] = letterToIndex(value);
    });
    return answerArr;
};
// 转换字母到数字
export const letterToIndex = function (letter) {
    // 确保传入的是单个大写字母
    if (
        typeof letter !== "string" ||
        letter.length !== 1 ||
        !letter.match(/[A-Z]/)
    ) {
        // throw new Error("输入必须是一个大写英文字母");
        return null;
    }

    // 使用 charCodeAt 获取字符的 Unicode 编码
    // 'A' 的 Unicode 编码是 65，所以我们减去 65 并加上 0 来获得从 0 开始的索引
    return letter.charCodeAt(0) - "A".charCodeAt(0);
};
// 转义包含unicode编码
export function parseUnicodeEscapes(unicodeStr) {
    console.log(unicodeStr)
    // 使用正则表达式匹配所有的 \uXXXX 序列，并用对应的字符替换
    return unicodeStr.replace(/\\u[\dA-Fa-f]{4}/g, function (match) {
        console.log(match)
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });
}
