/*
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2025-01-10 13:03:05
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 13:04:14
 * @FilePath: \exam-fe\src\store\TimeHook.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 格式化日期函数
export const formatDate = function (dateStr) {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return date.toLocaleString();
};
// 判断时间是否到达函数
export const isTimeReached = function (startTime, endTime) {
    // 创建目标时间的Date对象
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    // 获取当前时间
    const currentDate = new Date();
    // 如果结束时间早于开始时间，假设是跨天的情况
    if (endDate < startDate) {
        // 检查当前时间是否在开始时间和午夜之间，或者在当天0点和结束时间之间
        const midnight = new Date(currentDate);
        midnight.setHours(0, 0, 0, 0); // 设置为当天0点

        return (
            (currentDate >= startDate && currentDate <= midnight) ||
            (currentDate >= new Date(midnight) && currentDate <= endDate)
        );
    } else {
        // 检查当前时间是否在开始时间和结束时间之间
        return currentDate >= startDate && currentDate <= endDate;
    }
};

export const calculateRemainingTime = function (targetTime) {
    // 创建目标时间的 Date 对象
    const targetDate = new Date(targetTime);

    // 获取当前时间
    const currentDate = new Date();
    return Math.floor((targetDate - currentDate) / 1000);
};