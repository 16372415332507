<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2025-01-08 20:58:17
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-12 16:13:04
 * @FilePath: \exam-fe\src\components\Admin\GradeManagement.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div id="gradeManagerMain">
        <a-spin :spinning="loading" size="large" />
        <div id="searchBar">
            <SearchBar SearchWidth="300px" @onSearch="getKeyword"/>
        </div>
        <a-table
            v-show="!loading"
            :data-source="examList.exams"
            :columns="columns"
            :pagination="{
                position: ['bottomCenter'],
                pageSize: examList.size,
                total: examList.total,
                page: examList.page,
                showSizeChanger: true, // 显示每页大小的选择器
                pageSizeOptions: ['10', '15', '20'], // 每页大小的选项
            }"
            @change="handlePageChange"
        >
            <!-- <template #headerCell="{ column }">
                <template v-if="column.dataIndex === 'title'">
                    <span style="color: #1890ff">考试</span>
                </template>
            </template> -->
            <template
                #customFilterDropdown="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                }"
            >
                <div style="padding: 8px">
                    <a-input
                        ref="searchInput"
                        :placeholder="`Search ${column.dataIndex}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="
                            (e) =>
                                setSelectedKeys(
                                    e.target.value ? [e.target.value] : []
                                )
                        "
                        @pressEnter="
                            handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                            )
                        "
                    />
                    <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="
                            handleSearch(
                                selectedKeys,
                                confirm,
                                column.dataIndex
                            )
                        "
                    >
                        <template #icon><SearchOutlined /></template>
                        搜索
                    </a-button>
                    <a-button
                        size="small"
                        style="width: 90px"
                        @click="handleReset(clearFilters)"
                    >
                        重置
                    </a-button>
                </div>
            </template>
            <template #customFilterIcon="{ filtered }">
                <search-outlined
                    :style="{ color: filtered ? '#108ee9' : undefined }"
                />
            </template>
            <template #bodyCell="{ text, column, record }">
                <span
                    v-if="
                        state.searchText &&
                        state.searchedColumn === column.dataIndex
                    "
                >
                    <template
                        v-for="(fragment, i) in text
                            .toString()
                            .split(
                                new RegExp(
                                    `(?<=${state.searchText})|(?=${state.searchText})`,
                                    'i'
                                )
                            )"
                    >
                        <mark
                            v-if="
                                fragment.toLowerCase() ===
                                state.searchText.toLowerCase()
                            "
                            :key="i"
                            class="highlight"
                        >
                            {{ fragment }}
                        </mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </span>
                <span
                    v-else-if="
                        column.dataIndex == 'startTime' ||
                        column.dataIndex == 'endTime'
                    "
                >
                    {{ formatDate(text) }}
                </span>
                <span v-else-if="column.key == 'action'">
                    <a-button
                        @click="toGradeOneExamLink(record.id, record.title)"
                        >查看</a-button
                    >
                </span>
            </template>
        </a-table>
    </div>
</template>
<script setup>
import { fetchExams } from "@/store/ExamListHook";
import { checkAdmin } from "@/utils/AuthTools";
import { formatDate } from "@/utils/TimeTools";
import { SearchOutlined } from "@ant-design/icons-vue";
import { onBeforeMount, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import SearchBar from "../SearchBar.vue";
// =======================================数据=========================================================================
// ======================工具======================
const router = useRouter();
// ======================普通数据======================
let loading = ref(false);
let examList = reactive({
    total: 0,
    page: 1,
    size: 10,
    exams: [],
});
const searchInput = ref();
const state = reactive({
    searchText: "",
    searchedColumn: "",
});
// {"id":7,"title":"测试","createdBy":1,"startTime":"2025-01-05T09:00:00","endTime":"2025-01-08T15:50:00","createdAt":"2025-01-08T07:47:43","updatedAt":"2025-01-08T07:47:43","description":"自动提交测试"}
const columns = [
    {
        title: "考试标题",
        dataIndex: "title",
        key: "id",
        customFilterDropdown: true,
        onFilter: (value, record) =>
            record.title.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        },
    },
    {
        title: "描述",
        dataIndex: "description",
        key: "id",
        ellipsis: true,
    },
    {
        title: "考试开始时间",
        dataIndex: "startTime",
        sorter: {
            compare: (a, b) => new Date(a.startTime) - new Date(b.startTime),
            multiple: 2, // 越大优先级越高
        },
    },
    {
        title: "考试结束时间",
        dataIndex: "endTime",
        sorter: {
            compare: (a, b) => new Date(a.endTime) - new Date(b.endTime),
            multiple: 1,
        },
    },
    {
        title: "操作",
        key: "action",
        width: "100px",
        align: "center",
    },
];
// ======================监视数据======================

// =======================================方法=========================================================================

// ======================自定义事件======================
const getKeyword = (searchValue) =>{
    fetchExams(examList, router, loading, searchValue);
}

// ======================普通函数======================

// 获取分页符的页码变更

const handlePageChange = function (pagination) {
    if(pagination.current != examList.page || pagination.pageSize != examList.size){
        examList.page = pagination.current
        examList.size = pagination.pageSize
        fetchExams(examList, router, loading);
    }
    
};

// 根据examid跳转页面查看成绩
const toGradeOneExamLink = (examId, title) =>
    router.push(
        `/backend/gradeManagementOneExam?examId=${examId}&title=${encodeURIComponent(
            title
        )}`
    );

// 搜索
const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    state.searchText = selectedKeys[0];
    state.searchedColumn = dataIndex;
};
// 重置
const handleReset = (clearFilters) => {
    clearFilters({
        confirm: true,
    });
    state.searchText = "";
};


// ======================生命周期======================
onMounted(() => {
    fetchExams(examList, router, loading);
});
onBeforeMount(() => {
    checkAdmin(router);
})
</script>
<style scoped>
#gradeManagerMain {
    width: 100%;
    background-color: #fff;
    padding: 24px;
    min-height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
}
#searchBar{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
}
</style>
