export const titleMap = {
    SINGLE_CHOICE: "单选题",
    TRUE_FALSE: "判断题",
    MULTIPLE_CHOICE: "多选题",
    PHOTOSHOP: "Ps操作题",
    PREMIERE: "Pr操作题",
};

export const choiceTypeMap = {
    SINGLE_CHOICE: 0,
    TRUE_FALSE: 0,
    MULTIPLE_CHOICE: 1,
    PHOTOSHOP: 2,
    PREMIERE: 2
};

export const fileTypeMap = {
    PHOTOSHOP: "psd",
    PREMIERE: "prproj"
}