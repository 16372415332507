<!--
 * @Author: 'ShenfanYin' '874321414@qq.com'
 * @Date: 2024-12-25 12:04:30
 * @LastEditors: 'ShenfanYin' 
 * @LastEditTime: 2024-12-27 18:43:40
 * @FilePath: \exam-fe\src\components\PromotionSystem\PromotionSystemLoginPage.vue
 * @Description: 该组件是一个推广的登录界面
-->

<template>
  <div class="LoginPage_container">
    <div class="container_login">
      <p class="container_login_login">登录</p>
      <div class="container_login_login_account">
        <span class="container_login_login_account_span">帐号:</span>
        <input class="container_login_login_account_input" v-model="username" type="text" placeholder="请输入账号" />
      </div>
      <div class="container_login_login_account">
        <span class="container_login_login_account_span">密码:</span>
        <input class="container_login_login_account_input" v-model="password" type="password" placeholder="请输入密码" />
      </div>
      <div class="container_login_login_button">
        <button class="login-button" @click="handleLogin">登录</button>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router'; // 导入 useRouter
export default {
  name: 'LoginComponent',
  setup() {
    // 使用 ref 来声明响应式数据
    const router = useRouter() // 获取路由实例
    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const loading = ref(false);  // 用于表示是否正在加载
    const showError = ref(false); // 控制弹窗是否显示

    // 模拟后端 API 请求（这里用一个简单的函数模拟）
    const authenticate = async (username, password) => {
      // 模拟延时，假装向服务器发起请求
      return new Promise((resolve) => {
        setTimeout(() => {
          // 这里假设 validUser 和 validPassword 是从服务器端验证来的
          const validUser = 'admin';
          const validPassword = '123456';
          
          // 模拟检查用户名和密码是否正确
          if (username === validUser && password === validPassword) {
            resolve(true);
          } else {
            alert('用户名或密码错误');  // 使用 alert 显示错误信息
          }
        }, 1000); // 模拟1秒的延时
      });
    };


    // 处理登录逻辑
    const handleLogin = async () => {
      // 清空之前的错误信息
      errorMessage.value = '';
      loading.value = true;
      showError.value = false; // 隐藏弹窗

      try {
        const result = await authenticate(username.value, password.value);
        if (result) {
          // 登录成功
          alert('登录成功');
          router.push({ path: '/PromotionSystemPage' }); // 使用 router.push() 进行路由跳转
          // 在这里可以进行跳转或保存登录状态等操作
        }
      } catch (error) {
        // 登录失败，显示错误信息
        errorMessage.value = error;
        showError.value = true; // 显示错误弹窗
        username.value = '';
        password.value = '';
      } finally {
        loading.value = false;  // 请求完成，停止加载状态
      }
    };

    // 关闭弹窗的方法
    const closeAlert = () => {
      showError.value = false;
    };

    return {
      username,
      password,
      errorMessage,
      loading,
      handleLogin,
      showError,
      closeAlert
    };
  },
};
</script>


<style scoped >

  .LoginPage_container{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;  /* 水平居中 */
    align-items: center;      /* 垂直居中 */
  }

  /* 登录的盒子 */
  .container_login {
    display: flex;
    flex-direction: column;
    width: 500px; /* 使用vw单位来设置宽度，相对于视口宽度 */
    max-width: 500px; /* 最大宽度限制 */
    height: 500px; /* 使用vh单位来设置高度，相对于视口高度 */
    background-color: #ffffff; /* 白色背景 */
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* 柔和的阴影 */
    padding: 2rem; /* 使用rem单位 */
    transition: transform 0.3s ease;
  }

  /* 登录字的属性 */
  .container_login_login {
    font-size: 2.5rem; /* 使用rem单位进行字体大小调整 */
    font-weight: bold;
    color: #2c3e50; /* 深蓝色字体 */
    text-align: center;
    margin-bottom: 1rem; /* 使用rem单位 */
  }

  .container_login_login_account_span {
    font-size: 1.2rem; /* 使用rem单位调整字体大小 */
    color: #333;
  }

  .container_login_login_account {
    margin-bottom: 2rem; /* 使用rem单位 */
  }

  /* 增加span和input之间的间距 */
  .container_login_login_account_span {
    margin-bottom: 1rem; /* 使用rem单位 */
    display: block;
  }

  .container_login_login_account_input {
    width: 100%;
    padding: 1rem 2rem; /* 使用rem单位 */
    font-size: 1rem; /* 使用rem单位 */
    border-radius: 6px;
    border: 1px solid #ddd; /* 浅灰色边框 */
    background-color: #f9f9f9; /* 浅灰色背景 */
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  /* 聚焦时的效果 */
  input:focus {
    outline: none;
    box-shadow: inset 0 0 5px rgba(0, 122, 255, 0.5); /* 内阴影 */
    border-color: #007bff; /* 聚焦时边框颜色 */
  }

  .container_login_login_button {
    display: flex;
    justify-content: center;
    
    margin-top: 1rem; /* 使用rem单位调整按钮上方的间距 */
  }

  /* 按钮样式 */
  .login-button {
    padding: 1rem 3rem; /* 使用rem单位 */
    width: 50%; /* 按钮宽度占父容器50% */
    font-size: 1.1rem; /* 使用rem单位 */
    background: linear-gradient(45deg, #007bff, #66aaff); /* 蓝色渐变背景 */
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background 0.3s ease;
  }

  /* 按钮悬停效果 */
  .login-button:hover {
    background: linear-gradient(45deg, #005bb5, #3380e1);
    transform: scale(1.05);
  }

  /* 按钮点击效果 */
  .login-button:active {
    transform: scale(0.98);
  }
</style>
