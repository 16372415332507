<template>
    <a-pagination :total="total"  v-model:current="currentPage"/>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from 'vue';
import { defineEmits } from "vue";
// =======================================数据=========================================================================

// ======================工具======================
const emit = defineEmits(["changePage"]);

// ======================父组件数据======================
const props = defineProps({
    total: {
        type: Number,
        required: true,
    },
    page: {
        type: Number,
        required: true,
    },
});
// ======================普通数据======================
let currentPage = ref(1)   // 初始化当前页面



// ======================监视数据======================
watch(currentPage, () => {
    emit("changePage", currentPage);
});

onMounted(() => {
    currentPage.value = props.page
});

</script>
<style scoped>
/* 控件没有留下去除修改每页数量的接口，强行删除一下，腊鸡控件 */
.ant-pagination-options{
    display: none !important;
}
</style>
