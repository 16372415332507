<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2025-01-08 14:56:54
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-08 15:09:50
 * @FilePath: \exam-fe\src\views\FinishLayout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div id="minContent">
        <a-result status="success" title="你已完成考试">
        <template #extra>
            <a-button key="console" type="primary" @click="goToRoot">返回登录页</a-button>
        </template>
    </a-result>
    </div>
    
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const goToRoot = function(){
    router.push("/")
}
</script>

<style scoped>
#minContent{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
