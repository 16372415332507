/*
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2025-01-07 15:51:10
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-07 17:21:42
 * @FilePath: \exam-fe\src\store\HandleChoiceChangeFactory.js
 * @Description: 选项变更时的工厂
 */
import { numberToLetters } from "@/utils/usefulTools";
function HandleChoiceChangeFactory(currentQuestion) {
    function handleSingleChoice() {
        return numberToLetters(currentQuestion.question.userAnswer);
    }
    function handleTrueFalse() {
        return currentQuestion.question.userAnswer == 0 ? true : false;
    }
    function handleMultipleChoice() {
        const sorted_arr = currentQuestion.question.userAnswer
            .slice()
            .sort((a, b) => a - b);
        sorted_arr.forEach(function (value, index, array) {
            array[index] = numberToLetters(value);
        });
        return sorted_arr.join("|||");
    }
    function handleFileUpload() {
        return currentQuestion.question.userAnswer;
    }
    return function handleChoiceFactory() {
        let submitAnswer;
        switch (currentQuestion.question.type) {
            case "SINGLE_CHOICE":
                submitAnswer = handleSingleChoice();
                break;
            case "TRUE_FALSE":
                submitAnswer = handleTrueFalse();
                break;
            case "MULTIPLE_CHOICE":
                submitAnswer = handleMultipleChoice();
                break;
            case "PHOTOSHOP":
                submitAnswer = handleFileUpload();
                break;
            case "PREMIERE":
                submitAnswer = handleFileUpload();
                break;
            default:
                console.warn(
                    "Unsupported question type:",
                    currentQuestion.question.type
                );
                break;
        }
        return submitAnswer;
    };
}

export default HandleChoiceChangeFactory;
