<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-29 20:43:49
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 13:09:23
 * @FilePath: \exam-fe\src\components\UserManager\UserExamList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div id="examMainContainer">
        <a-spin :spinning="loading" size="large" />

        <a-empty
            id="noExam"
            description="无考试数据"
            v-if="examList.total === 0 && !loading"
        />

        <div class="examDisplayContainer" v-else-if="!loading">
            <div
                class="examItemBox"
                v-for="(item, index) in examList.exams"
                :key="index"
            >
                <div class="examItemHeader">
                    <span>
                        <b>{{ item.title }}</b>
                    </span>
                </div>
                <div class="examItemContent">
                    <div class="examItemInfo">
                        <span>开始时间：{{ formatDate(item.startTime) }}</span>
                    </div>
                    <div class="examItemInfo">
                        <span>结束时间：{{ formatDate(item.endTime) }}</span>
                    </div>
                    <div class="examItemInfo">
                        考试状态：
                        <span v-if="isTimeReached(item.startTime, item.endTime)">
                            <a-tag color="success">
                                <template #icon>
                                    <sync-outlined :spin="true" />
                                </template>
                                考试进行中
                            </a-tag>
                        </span>
                        <span v-else>
                            <a-tag color="default">
                                <template #icon>
                                    <clock-circle-outlined />
                                </template>
                                不在考试时间
                            </a-tag>
                        </span>
                    </div>

                    <div
                        class="examItemDscript examItemInfo"
                        :title="item.description"
                    >
                        <span>详情：{{ item.description }}</span>
                    </div>
                </div>
                <div class="examItemFooter">
                    <div class="examItemStart" @click="startExam(item)">
                        <span>进入考试</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="examFooter" v-if="examList.total !== 0">
            <PageBar
                :total="examList.total"
                :page="examList.page"
                :pageSize="examList.size"
                @changePage="getCurrentPage"
            />
        </div>
    </div>
</template>

<script setup>
import {
    SyncOutlined,
    ClockCircleOutlined,
} from "@ant-design/icons-vue";
import { fetchExams } from "@/store/ExamListHook";
import { message } from "ant-design-vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import PageBar from "../PageBar.vue";
import { formatDate, isTimeReached } from "@/utils/TimeTools";

// =======================================数据=========================================================================

// ======================工具======================
const router = useRouter();
// ======================普通数据======================
let examList = reactive({
    total: 0,
    page: 1,
    size: 8,
    exams: [],
});
let loading = ref(false);
// =======================================方法=========================================================================

// 开始考试
const startExam = function (examItem) {
    if (isTimeReached(examItem.startTime, examItem.endTime))
        router.push({ name: "front", query: { examId: examItem.id } });
    else message.error("不在考试时间");
};

// ======================自定义事件======================
// 获取分页符的页码变更
const getCurrentPage = function (currentPage) {
    examList.page = currentPage;
    fetchExams(examList, router, loading);
};

// =======================================生命周期=========================================================================
onMounted(() => {
    fetchExams(examList, router, loading);
});
</script>

<style scoped>
#examMainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#examFooter {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* 默认情况下，单列布局 */
.examDisplayContainer {
    padding: 20px;
    margin: 20px;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 50px;
    column-gap: 20px;
    place-items: center;
    flex: 1;
}

/* 当视口宽度大于 1000px 时，变为两列 */
@media (min-width: 1000px) {
    .examDisplayContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* 当视口宽度大于 1200px 时，固定三列 */
/* @media (min-width: 1200px) {
    .examDisplayContainer {
        grid-template-columns: repeat(3, 1fr);
    }
} */

@media (min-width: 1600px) {
    .examDisplayContainer {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1900px) {
    .examItemBox {
        width: 400px !important;
    }
}
.examItemBox {
    letter-spacing: 2px;
    font-size: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 90%;
    height: 300px;
    display: flex;
    flex-direction: column;
    /* align-items: center;   中间的是居中还是左对齐   */
}
.examItemHeader {
    word-break: break-all; /* 强制在任何字符处换行 */
    overflow-wrap: break-word; /* 允许长单词或 URL 断行 */
    white-space: pre-wrap; /* 保留空白符序列，但正常地换行 */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    min-height: 40px;
    border-bottom: 0.5px solid rgb(200, 199, 199);
}
.examItemContent {
    flex: 1;
    max-width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
}
.examItemInfo {
    flex: 2;
    padding: 2px;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.examItemFooter {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.examItemDscript {
    flex: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 5px 0;
    padding: 10px 0;
    border-top: 0.5px dashed rgb(200, 199, 199);
    word-break: break-all; /* 强制在任何字符处换行 */
    overflow-wrap: break-word; /* 允许长单词或 URL 断行 */
    white-space: pre-wrap; /* 保留空白符序列，但正常地换行 */
    overflow: hidden;
    -webkit-line-clamp: 3; /* 设置最大行数 */
    overflow: hidden;
    text-overflow: ellipsis;
}

.examItemStart {
    user-select: none;
    width: 100px;
    height: 35px;
    background-color: #001529;
    border-radius: 5px;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
}
.examItemStart:hover {
    background-color: #1677ff;
}
#noExam {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
