<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-29 20:31:43
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 13:08:47
 * @FilePath: \exam-fe\src\components\UserManager\CheckUserInfo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="content">
    <div class="exam-notice">
      <h1>线上考试注意事项</h1>

      <section>
        <h2>一、考试准备</h2>
        <ol>
          <li>工具准备：本次考试全程视频监控，需准备有摄像头的 PC 端及支持摄像的手机。</li>
          <li>在考试开始前建议将手机调为飞行模式，并连接 WiFi，以防考试时途径电信或短信中断，开考前确认手机电量充足，确保网络连接顺畅。</li>
          <li>软件准备：请确保作答前关闭弹窗、广告的软件或网页，如 QQ、微信、360、迅雷等。请确保您的电脑安装了 Chrome 浏览器（63 以上版本）。</li>
          <li>请在明亮的环境下作答，保证摄像清晰，系统会进行人脸识别验证。</li>
          <li>提前准备好身份验证，考试前进行手持身份证拍照上传。</li>
        </ol>
      </section>

      <section>
        <h2>二、线上模拟考试说明</h2>
        <ol>
          <li>模拟测试时间：1月25日上午10:00到12:00，请考生在此期间登录系统进行测试。</li>
          <li>线上模拟测试不计分数，仅供考生熟悉考试系统并测试测试软件硬件环境。</li>
          <li>测试考生入口预计于1月25日下午发送，请注意查收。</li>
        </ol>
      </section>

      <section>
        <h2>三、线上正式考试说明</h2>
        <ol>
          <li>正式考试时间：1月27日下午14:00–17:00，请考生携带身份证参加考试，并提前15分钟登录系统，未携带相关证件，或迟到30分钟者取消考试资格。</li>
          <li>正式考试入口预计于1月25日下午发送，请注意查收。</li>
        </ol>
      </section>

      <section>
        <h2>四、其他注意事项</h2>
        <ol>
          <li>如遇断网或不可小心关闭考试页面，您可再次登录考试页面继续作答，系统会自动保存部分作答进度。</li>
          <li>如遇页面点击无反应，题目加载不出来等卡顿网络繁忙的现象，建议先退出测试页面后更换网络环境再进入作答。</li>
          <li>考试过程中，禁止使用耳机、手机、笔记本电脑等电子设备，禁止伪装摄像头或遮挡摄像头。</li>
        </ol>
      </section>

      <section>
        <h2>五、考试答疑</h2>
        <ol>
          <li>如遇技术问题，请联系浙江安防职业技术学院-严一格-18243000589</li>
        </ol>
      </section>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { authCheck } from "@/utils/AuthTools";
import { useRouter } from "vue-router";
let username = ref("");
const router = useRouter();
const getInfo = async function () {
    const userInfo = await authCheck(router);
    if (userInfo.username == undefined || !userInfo.username) router.push("/");
    username.value = userInfo.username;
};

onMounted(() => {
    getInfo();
});
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.exam-notice {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.exam-notice h1 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.exam-notice h2 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.exam-notice ol {
  list-style-position: inside;
}

.exam-notice li {
  margin-bottom: 10px;
}

.exam-notice p {
  font-size: 1.1em;
  line-height: 1.5;
}
</style>
