<!--
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-23 17:51:12
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 13:10:04
 * @FilePath: \exam-fe\src\views\FrontLayout.vue
 * @Description: 这是前台答题界面
-->

<template>
    <!-- 提交确认框 -->
    <a-modal
        title="交卷确认"
        v-model:open="modalData.open"
        :confirm-loading="modalData.confirmLoading"
        cancelText="取消"
        okText="确认"
        @ok="handleModalOk"
    >
        <p>{{ modalData.text }}</p>
    </a-modal>

    <a-layout id="frontContainer">
        <!-- 左侧题目选择的栏目（浅色主题），width属性配置左侧栏宽度(css样式优先级不够)与右侧栏marginLeft对应 -->
        <a-layout-sider id="sider" theme="light" :width="'400px'">
            <div class="logo">考试系统</div>
            <QuestionChoiceBox
                v-for="(value, key) in questionList"
                :key="key"
                :title="key"
                :questions="value"
                @clickbox="getCurrentQuestion"
            />
        </a-layout-sider>
        <!-- 右侧主体部分，设置最大高度和最小高度等同，不允许出现滚动条 -->
        <a-layout id="mainContent">
            <!-- 头部栏目，之后要换计时的bar -->
            <a-layout-header :style="{ padding: 0 }">
                <div id="headerBox">
                    <div id="userNameBox" class="fatherBox">
                        {{ userName }}
                    </div>
                    <div id="timeDisplayBox" class="fatherBox">
                        <span>剩余时间： {{ ramainTimeFormated }}</span>
                    </div>
                    <div id="submitBox" class="fatherBox" @click="submitExam">
                        <span>交卷</span>
                    </div>
                </div>
            </a-layout-header>
            <!-- 右侧内容显示区，设置了超出盒子自动滚动条 -->
            <a-layout-content id="backBox">
                <a-empty
                    id="noQuestion"
                    description="无题目数据，请检查是否登录、是否到达考试时间"
                    v-if="Object.keys(currentQuestion.question).length === 0"
                />
                <QuestionDisplayBox
                    v-else
                    :totalNumber="totalNumber"
                    :AnswerNumber="AnswerNumber"
                ></QuestionDisplayBox>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script setup>
import {
    ref,
    onMounted,
    computed,
    reactive,
    provide,
    onBeforeUnmount,
} from "vue";
import { get, post } from "@/utils/request";
import { message } from "ant-design-vue";
import QuestionChoiceBox from "@/components/Question/QuestionChoiceBox.vue";
import QuestionDisplayBox from "@/components/Question/QuestionDisplayBox.vue";
import { questionEmitter } from "@/store/bus";
import { useRouter, useRoute } from "vue-router";
import { authCheck } from "@/utils/AuthTools";
import { calculateRemainingTime } from "@/utils/TimeTools";
import TransUserAnswerFactory from "@/store/TransUserAnswerFactory";
// =======================================数据=========================================================================

// ======================工具======================
const router = useRouter();
const route = useRoute();
// ======================普通数据======================
// 这里可以省略displayedId，看怎么改
let currentQuestion = reactive({
    displayedId: 1,
    question: {},
});
let questionList = reactive({
    SINGLE_CHOICE: [],
    TRUE_FALSE: [],
    MULTIPLE_CHOICE: [],
    PHOTOSHOP: [],
    PREMIERE:[],

});
let FLAT_QUESTIONS = reactive([]);
let userName = ref('');
// 一些计时器的参数
let remainTime = ref(0);
let countdownInterval; // 考试倒计时
// 提交相关
let examId; // 考试id
let submitFlag = ref(false);   // 提交要最后一题提交答案，设置flag，监视变化
let isExam = false;   // 判断是否是正常的考试，防止非法进入时倒计时清空跳转的bug
let modalData = reactive({
    open: false, //是否显示
    text: null, //提示文字
    confirmLoading: false, //confirm确认的loading控制
});
// ======================计算属性======================
// 总共多少题
const totalNumber = computed(() => {
    return FLAT_QUESTIONS.length;
});
// 完成多少题
const AnswerNumber = computed(() => {
    return FLAT_QUESTIONS.filter(
        (item) => item.userAnswer !== null && item.userAnswer !== undefined  && item.userAnswer.length !== 0
    ).length;
});
const ramainTimeFormated = computed(() => {
    const minutes = Math.floor(remainTime.value / 60);
    const seconds = remainTime.value % 60;
    return `${minutes} 分 ${seconds} 秒`;
});
// ======================提供给别人用的数据======================
provide("currentQuestion", currentQuestion);

// =======================================方法=========================================================================

// 获取全部题目数据(本地fastapi测试)
const fetchAllQuestion = async (examId) => {
    try {
        const response = await get(
            // "http://127.0.0.1:8080/api/exams/1/questions"
            "/exams/" + examId + "/questions/details"
        );
        Object.assign(questionList, response.data.questions);
        remainTime.value = calculateRemainingTime(response.data.endTime);
        if (remainTime.value > 0)
            isExam = true;
        // 获取初始题目
        currentQuestion.question =
            questionList["SINGLE_CHOICE"][0] ||
            questionList["TRUE_FALSE"][0] ||
            questionList["MULTIPLE_CHOICE"][0] ||
            questionList["PHOTOSHOP"][0] ||
            questionList["PREMIERE"][0] ||
            {};
        FLAT_QUESTIONS = Object.values(questionList).flat();
        // 给每个题目增加属性ismarked, flatIndex(用来上一题下一题)，初始化userAnswer
        let flatIndex = 0;
        Object.keys(questionList).forEach((key) => {
            questionList[key].forEach((item, index) => {
                item.displayedId = index + 1;
                item.isMarked = false; // 是否被标记
                item.flatIndex = flatIndex;
                flatIndex += 1;
                // 处理一下字符串变成合适的答案形式
                const transUserAnsFunction = TransUserAnswerFactory(item);
                item.userAnswer = transUserAnsFunction(item);
            });
        });
    } catch (error) {
        if (error.code == "401") {
            message.error(error.message, 2, () => router.push("/login"));
        } else if (error.code == "403") {
            message.error(error.message, 2, () => router.back());
        } else {
            console.error("获取题目数据失败:", error);
            message.error(error.message, 2, () => router.back());
        }
    }
};

// 交卷按钮点击
const submitExam = function () {
    const unfinished = totalNumber.value - AnswerNumber.value;
    if (unfinished > 0)
        modalData.text = `你还有${unfinished}题未回答，是否交卷？`;
    else modalData.text = `是否交卷？`;
    modalData.open = true;
};
// 交卷确认回调函数
const handleModalOk = async function () {
    modalData.text = "提交中，请稍后";
    modalData.confirmLoading = true;
    await submitExamPost();
};
// 交卷post函数
const submitExamPost = async function () {
    try {
        submitFlag.value = !submitFlag.value;
        await post("/exams/submit?examId=" + examId);
        modalData.open = false;
        router.push("/finishExam");
    } catch (error) {
        message.error(error.message);
        modalData.open = false;

    }
};
// 获取userinfo
const getInfo = async function(){
  const userInfo = await authCheck(router);
  if (userInfo.username == undefined || !userInfo.username) router.push("/");
  userName.value = userInfo.username
}

// =======================================自定义事件=========================================================================
// 点击按钮返回当前题目的信息给frontlayout
const getCurrentQuestion = function (qusetionItem) {
    currentQuestion.question = qusetionItem;
    currentQuestion.displayedId = qusetionItem.displayedId;
};
const updataTime = function () {
    if (remainTime.value > 0) {
        remainTime.value--;
    } else {
        clearInterval(countdownInterval);
        if(isExam)
            router.push("/finishExam");
    }
};

// =======================================生命周期钩子=========================================================================
onMounted(() => {
    getInfo()
    // 获取全部数据
    examId = route.query.examId;
    if (!examId) message.error("非法进入", 2, () => router.back());
    fetchAllQuestion(examId);
    countdownInterval = setInterval(updataTime, 1000);
    // ======================订阅事件======================
    // ismarked, 来源：components\Question\QuestionDisplayBar.vue
    questionEmitter.on("changeMarkedStatus", getMarkedStatus);
    // 下一题, 来源：components\Question\QuestionDisplayBar.vue
    questionEmitter.on("toNextQuestion", getNextQuestion);
    // 上一题, 来源：components\Question\QuestionDisplayBar.vue
    questionEmitter.on("toPredQuestion", getPredQuestion);
});
onBeforeUnmount(() => {
    // ======================解除事件======================
    questionEmitter.off("changeMarkedStatus", getMarkedStatus);
    questionEmitter.off("toNextQuestion", getNextQuestion);
    questionEmitter.off("toPredQuestion", getPredQuestion);
    if (countdownInterval) clearInterval(countdownInterval);
});

// =======================================订阅事件函数=========================================================================
const getMarkedStatus = () => {
    currentQuestion.question.isMarked = !currentQuestion.question.isMarked;
};
const getNextQuestion = () => {
    const nextIndex =
        (currentQuestion.question.flatIndex + 1) % FLAT_QUESTIONS.length;
    currentQuestion.question = FLAT_QUESTIONS[nextIndex];
    currentQuestion.displayedId = currentQuestion.question.displayedId;
};
const getPredQuestion = () => {
    const predIndex =
        (currentQuestion.question.flatIndex - 1 + FLAT_QUESTIONS.length) %
        FLAT_QUESTIONS.length;
    currentQuestion.question = FLAT_QUESTIONS[predIndex];
    currentQuestion.displayedId = currentQuestion.question.displayedId;
};
</script>

<style scoped>
.fatherBox {
    display: flex;
    align-items: center;
    justify-content: center;
}
#headerBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}
#userNameBox {
    padding: 0 20px;
    color: white;
    font-size: 16px;
}
#timeDisplayBox {
    padding: 0 20px;
    color: white;
    font-size: 16px;
    text-align: center;
    flex: 1;
}
#submitBox {
    font-size: 16px;
    width: 80px;
    height: 70%;
    color: white;
    font-size: 16px;
    background-color: #40a9ff;
    border-radius: 5px;
    cursor: pointer;
}
#submitBox:hover {
    background-color: #60be58;
}
#frontContainer {
    user-select: none;
}
.logo {
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: rgba(151, 151, 151, 0.2);
    margin: 16px;
}
#main {
    padding: 24px;
    background-color: #fff;
    height: 100%;
    overflow: auto;
}
#sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    border-right: 1px gray solid;
}
#mainContent {
    margin-left: 400px;
    height: 100vh;
}
#backBox {
    margin: 24px 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #fff;
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.5);
}
#noQuestion {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.site-layout .site-layout-background {
    background: #fff;
}

[data-theme="light"] .site-layout .site-layout-background {
    background: #141414;
}
</style>
