<template>
  <div class="LiveRoomPromotionAdd_container">

    <div class="LiveRoomPromotionAdd_container_body">
      <div class="LiveRoomPromotionAdd_container_body_title">
            <p>直播间推广</p>
            <ins></ins>
      </div>
    </div>
  
  </div>

</template>

<script>

</script>

<style scoped>
.LiveRoomPromotionAdd_container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: rgb(208, 225, 55);
}
.LiveRoomPromotionAdd_container_body{
  width: 50%;
  height: 50%;
  background-color: aquamarine;
}
</style>