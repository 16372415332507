/*
 * @Author: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @Date: 2024-12-27 18:19:33
 * @LastEditors: 'YouranShan' '9488429+youranshan@user.noreply.gitee.com'
 * @LastEditTime: 2025-01-10 16:12:42
 * @FilePath: \exam-fe\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory } from "vue-router";
import BackLayout from "@/views/BackLayout.vue";
import PromotionSystemLoginPage from "@/components/PromotionSystem/PromotionSystemLoginPage.vue";
import FrontLayout from "@/views/FrontLayout.vue";
import PromotionSystemMarketingPromotion from "@/components/PromotionSystem/PromotionSystemMarketingTableComponent/PromotionSystemPagePromotion.vue";
import PromotionSystemPageTable from "@/components/PromotionSystem/PromotionSystemMarketingTableComponent/PromotionSystemPageTable.vue";
import PromotionSystemPage from "@/components/PromotionSystem/PromotionSystemPage.vue";
import LiveRoomPromotionAdd from "@/components/PromotionSystem/PromotionSystemMarketingTableComponent/LiveRoomPromotionAdd.vue";
import LoginLayout from "@/views/LoginLayout.vue";
import UserExamLayout from "@/views/UserExamLayout.vue";
import UserCheckInfo from "@/components/UserManager/UserCheckInfo.vue";
import UserExamList from "@/components/UserManager/UserExamList.vue";
import FinishLayout from "@/views/FinishLayout.vue";
import GradeManagement from "@/components/Admin/GradeManagement.vue";
import GradeManagementOneExam from "@/components/Admin/GradeManagementOneExam.vue";
import GradeManagementDetail from "@/components/Admin/GradeManagementDetail.vue";
import ExamManagement from "@/components/Admin/ExamManagement.vue";
import ExamManagementCreate from "@/components/Admin/ExamManagementCreate.vue";
import ExamManagementDetail from "@/components/Admin/ExamManagementDetail.vue";

const routes = [
    // 根
    { path: "/", redirect: '/login',},
    { path: "/login", component: LoginLayout },
    // 普通用户管理界面
    {
        path: "/userManager",
        component: UserExamLayout,
        children: [
            {
                path: "/userManager/userCheckInfo",
                component: UserCheckInfo,
            },
            {
                path: "/userManager/userExamList",
                component: UserExamList,
            },
        ],
    },
    // 普通用户答题界面
    {
        path: "/front",
        name: "front",
        component: FrontLayout,
    },
    // 完成答题界面
    {
        path: "/finishExam",
        name: "finishExam",
        component: FinishLayout,
    },
    // 管理员用户管理界面
    {
        path: "/backend",
        component: BackLayout,
        redirect: '/backend/examManagement',
        children: [
            {
                path: "/backend/examManagement",
                component: ExamManagement,
            },
            {
                path: "/backend/examManagementCreate",
                component: ExamManagementCreate,
            },
            {
                path: "/backend/examManagementDetail",
                name: "examManagementDetail",
                component: ExamManagementDetail,
            },
            {
                path: "/backend/gradeManagement",
                component: GradeManagement,
                name: "gradeManagement",
            },
            {
                path: "/backend/gradeManagementOneExam",
                component: GradeManagementOneExam,
            },
            {
                path: "/backend/gradeManagementDetail",
                component: GradeManagementDetail,
            },
            
        ],
    },
    {
        path: "/PromotionSystemLoginPage",
        component: PromotionSystemLoginPage,
    },
    {
        path: "/PromotionSystemMarketingPromotion",
        component: PromotionSystemMarketingPromotion,
    },
    {
        path: "/PromotionSystemPageTable",
        component: PromotionSystemPageTable,
    },
    {
        path: "/PromotionSystemPage",
        component: PromotionSystemPage,
    },
    {
        path: "/LiveRoomPromotionAdd",
        component: LiveRoomPromotionAdd,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("satoken");
    // 如果目标路径不是/login且没有token，跳转到/login
    if (to.path !== "/login" && !token && to.path !== "/") {
        next("/login");
    } else {
        next();
    }
});

export default router;
